$color-seperator-1: #cccccc;

$color-box-shadow-1: rgba(0, 0, 0, 0.05);
$color-box-shadow-2: rgba(0, 0, 0, 0.25);
$color-box-shadow-3: rgba(0, 0, 0, 0.75);
$color-box-shadow-4: rgba(102, 175, 233, 0.6);

$color-text-1: #333333;
$color-text-2: #666666;
$color-text-3: #ffffff;
$color-text-4: #999999;
$color-text-5: #000000;
$color-text-6: #89cce4;
$color-text-7: #1d5aa6;
$color-text-8: #1b96d4;
$color-text-9: #888888;
$color-text-10: #ff0000;
$color-text-11: #1e97d4;
$color-text-12: #262626;
$color-text-13: #555555;
$color-text-14: #b8def1;
$color-text-15: #5cb85c;

$color-background-1: #ffffff;
$color-background-2: #f5f5f5;
$color-background-3: #fbfbfb;
$color-background-4: #eeeeee;
$color-background-5: #1b94d1;
$color-background-6: #1d5aa6;
$color-background-7: #888888;
$color-background-8: #6f6f6f;
$color-background-9: #1e97d4;
$color-background-10: transparent;

$color-border-1: #ddd;
$color-border-2: #ffffff;
$color-border-3: #9cbbda;
$color-border-4: transparent;
$color-border-5: rgba(0, 0, 0, 0.15);
$color-border-6: #1e97d4;
$color-border-7: #a94442;
$color-border-8: #555555;
$color-border-9: #1d5aa6;
$color-border-10: #000000;

$color-border-bottom-1: #eeeeee;
$color-border-bottom-2: #e8e8e8;
$color-border-bottom-3: #1b96d4;

// Anchor links or click buttons
$color-link-1: #1e97d4;
$color-link-2: #999999;

$color-link-hover-1: #1e97d4;

$color-accent-iris: #5f60ff;

$color-link-select-1: #333333;
