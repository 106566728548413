.customer-success-page-wrapper {
  .static-template-header h3 {
    text-transform: initial;
  }

  .customer-success-toggle-wrapper {
    border-bottom: 1px solid $color-border-bottom-1;
    padding-bottom: 15px;
    .toggle-parent-wrapper {
      margin: 20px 0 0;
      font-size: 18px;
      color: $color-text-8;

      display: -ms-flexbox;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .toggle-icon {
        padding-top: 13px;
      }

      .static-template-subHeader {
        border-bottom: none;
        padding: 0;
      }
    }

    .toggle-child-wrapper {

      &.show-child-wrapper {
        max-height: none;
      }

      .customer-success-toggle-content-wrapper {
        width: 100%;
        padding: 10px 0 0;
  
        &.customer-success-customer-overview-content {
          .video-wrapper {
            margin: 5px 0 0;
            padding: 0 0 15px;
        
            >div {
              padding: 0px 30px;
        
              video {
                border: 1px solid $color-border-6;
              }
            }
          }
        }
      }
    }
  }
}
