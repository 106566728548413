.myApps-page {
  padding-bottom: 30px;

  .company-info-wrapper {
    padding-top: 20px;
  }

  .alert-danger {
    background-color: #f2dede !important;
    border-color: #ebccd1 !important;
    color: #a94442 !important;
  }

  .alert-success {
    background-color: #2bc2747b;
    border-color: #2bc274ae;
    color: black;
  }

  .alert-error {
    background-color: #2bc2747b;
    border-color: #2bc274ae;
    color: black;
  }

  .display-message-container {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .myApps-header-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;

    .myApps-header-left-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      .static-template-header {
        border: none;
      }
    }

    .myApps-header-right-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 2;

      display: -ms-flexbox;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .myApps-header-actions {
        margin: 0 10px 10px 0;
  
        &:last-child {
          margin: 0 0 10px;
        }
      }
    }
  }

  .myApps-details-wrapper {
    .toggle-parent-wrapper {
      margin: 20px 0;
      font-size: 18px;
      color: $color-text-8;

      .toggle-icon {
        padding-top: 8px;
      }

      .app-detail-intro-wrapper {
        display:-ms-grid;
        display: grid;
        -ms-grid-columns: 25fr 6fr;
        grid-template-columns: 25fr 6fr;

        .app-detail-name-desc-wrapper {
          -ms-grid-row: 1;
          -ms-grid-column: 1;

          .app-detail-name {
            font-size: 24px;
          }

          .app-detail-description {
            font-size: 15px;
            color: $color-text-2;
          }
        }

        .app-detail-date-time {
          -ms-grid-row: 1;
          -ms-grid-column: 2;

          white-space: nowrap;
          text-align: right;
          color: $color-text-2;

          .app-warning {
            margin-right: 10px;
          }
        }
      }
    }
      
    .app-detail-multi-information-wrapper {
      margin-left: 10px;
      width: 100%;
      display: grid;
      grid-template-rows: auto auto;        

      display: -ms-grid;
      -ms-grid-rows: auto auto;

      .app-detail-multi-key-wrapper {
        margin-right: 10px;
        border: 1px solid $color-background-4;
        padding-top: 10px;
      }

      .app-detail-info-wrapper {
        display: grid;
        grid-template-columns: 37.5% 22.5% 40%;

        display: -ms-grid;
        -ms-grid-columns: 37.5% 25% 37.5%;

        padding-top: 10px;
        .app-detail-multi-info-left-wrapper {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        .app-detail-multi-info-center-wrapper {
          -ms-grid-row: 1;
          -ms-grid-column: 1;
        }
        .app-detail-multi-info-right-wrapper {
          -ms-grid-row: 1;
          -ms-grid-column: 2;
        }
      }        

      >div {
        padding: 0 15px 15px;
      }
    }

    .app-detail-information-wrapper {
      margin-left: 10px;
      width: 100%;
      display: grid;
      grid-template-columns: 50% 50%;

      display: -ms-grid;
      -ms-grid-columns: 50% 50%;

      .app-detail-info-left-wrapper {
        -ms-grid-row: 1;
        -ms-grid-column: 1;
      }
      .app-detail-info-right-wrapper {
        -ms-grid-row: 1;
        -ms-grid-column: 2;
      }

      >div {
        padding: 0 15px 15px;
      }
    }

    .app-detail-content-wrapper {
      margin-bottom: 10px;
      .app-detail-title {
        font-weight: bold;
        font-size: 16px;
      }
      
      .app-detail-value {
        font-size: 14px;
        .app-detail-product-values {
          padding-block: 2px;
        }
      }

      .app-detail-button {
        font-size: 15px;
        margin-right: 30px;

        &:active {
          border: 2px solid #8080ff;
        }
        
        a {
          &:hover {
            text-decoration-color: #8080ff;
            text-decoration-thickness: 2px;
          }
        
          &:active {
            text-decoration-color: #2bc275;
            outline: 2px solid transparent;
          }
        }
      }
    }
  }
} 
