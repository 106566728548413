.intro-wrapper-with-outer-image {
  display: table;
  width: 100%;
  min-height: 100%;
  width: 100%;
  min-height: 460px;
  background-image:
    linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)),
    url(../../images/autodesk_sample_banner.jpg);
  background-size: cover;
  background-position: center;

  .intro-content-link-button {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .intro-wrapper-with-inner-image {
    background-position: left bottom;
    background-repeat: no-repeat;
    display: table-cell;
    vertical-align: middle;
    padding: 30px 0;

    .display-message-container {
      position: relative;
      margin: 30px auto;
      border: 1px solid $color-border-8;
    }

    .intro-content-wrapper {
      margin: 0;
      margin-right: 20px;

      .intro-content-header {
        font-size: 40px;
        line-height: 52px;
        margin: 10px 0;
        color: $basics-colors-primary-white;
        text-transform: capitalize;
        font-weight: 800;
        font-family: ArtifaktLegend, ArtifaktElement, sans-serif;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
      }

      .intro-content-sub-header {
        color: $basics-colors-primary-charcoal-300;
        font-size: 17px;
        margin: 0 0 15px;
      }

    }
  }
}
