.toggle-container-wrapper {
  cursor: pointer;

  .toggle-user-options-wrapper {
    position: relative;

    .toggle-arrow {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      margin-top: 0;
      vertical-align: middle;
      border-top: 4px dashed;
      border-top: 4px solid;
      border-right: 4px solid $color-border-4;
      border-left: 4px solid $color-border-4;
      color: $color-text-11;
    }

    .user-navigation-wrapper {
      margin: 0;
      position: absolute;
      top: 23px;
      left: -175px;
      z-index: 100;
      min-width: 210px;
      padding: 5px 0;
      text-align: left;
      background-color: $color-background-1;
      border: 1px solid $color-border-5;
      border-radius: 0;
      -webkit-box-shadow: 0 6px 12px $color-box-shadow-1;
      box-shadow: 0 6px 12px $color-box-shadow-1;
      background-clip: padding-box;
      color: $color-text-1;

      > div {
        padding: 8px 16px;

        &:hover {
          color: $color-text-12;
          background-color: $color-background-2;
        }

        a {
          display: block;
          text-decoration: none;

          span {
            display: inline;
            color: $basics-colors-primary-black;
          }
          .toggle-icons {
            margin-right: 10px;
          }

          &:focus {
            outline: 2px solid transparent;
          }
        }
      }
    }
  }
}
