/* COPIED STYLE.CSS from OLD UI */

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%
}

body {
  margin: 0
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline
}

audio:not([controls]) {
  display: none;
  height: 0
}

[hidden],
template {
  display: none
}

a {
  background-color: transparent
}

a:active,
a:hover {
  outline: 0
}

abbr[title] {
  border-bottom: 1px dotted
}

b,
strong {
  font-weight: bold
}

dfn {
  font-style: italic
}

h1 {
  font-size: 2em;
  margin: 0.67em 0
}

mark {
  background: #ff0;
  color: #000
}

small {
  font-size: 80%
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline
}

sup {
  top: -0.5em
}

sub {
  bottom: -0.25em
}

img {
  border: 0
}

svg:not(:root) {
  overflow: hidden
}

figure {
  margin: 1em 40px
}

hr {
  box-sizing: content-box;
  height: 0
}

pre {
  overflow: auto
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0
}

button {
  overflow: visible
}

button,
select {
  text-transform: none
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer
}

button[disabled],
html input[disabled] {
  cursor: default
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0
}

input {
  line-height: normal
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto
}

input[type=search] {
  -webkit-appearance: textfield;
  box-sizing: content-box
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em
}

legend {
  border: 0;
  padding: 0
}

textarea {
  overflow: auto
}

optgroup {
  font-weight: bold
}

table {
  border-collapse: collapse;
  border-spacing: 0
}

td,
th {
  padding: 0
}

/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {

  *,
  :after,
  :before {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  a,
  a:visited {
    text-decoration: underline
  }

  a[href]:after {
    content: " ("attr(href) ")"
  }

  abbr[title]:after {
    content: " ("attr(title) ")"
  }

  a[href^="#"]:after,
  a[href^="javascript:"]:after {
    content: ""
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  img,
  tr {
    page-break-inside: avoid
  }

  img {
    max-width: 100% !important
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3
  }

  h2,
  h3 {
    page-break-after: avoid
  }

  .navbar {
    display: none
  }

  .btn>.caret,
  .dropup>.btn>.caret {
    border-top-color: #000 !important
  }

  .label {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td,
  .table th {
    background-color: #fff !important
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  color: #333333;
  background-color: #fff
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

figure {
  margin: 0
}

img {
  vertical-align: middle
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto
}

.img-rounded {
  border-radius: 0
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto
}

.img-circle {
  border-radius: 50%
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto
}

[role=button] {
  cursor: pointer
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: normal;
  line-height: 1;
  color: #777777
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%
}

.h1,
h1 {
  font-size: 36px
}

.h2,
h2 {
  font-size: 30px
}

.h3,
h3 {
  font-size: 24px
}

.h4,
h4 {
  font-size: 18px
}

.h5,
h5 {
  font-size: 14px
}

.h6,
h6 {
  font-size: 12px
}

p {
  margin: 0 0 10px
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4
}

@media (min-width:768px) {
  .lead {
    font-size: 21px
  }
}

.small,
small {
  font-size: 85%
}

.mark,
mark {
  background-color: #fcf8e3;
  padding: .2em
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.text-center {
  text-align: center
}

.text-justify {
  text-align: justify
}

.text-nowrap {
  white-space: nowrap
}

.text-lowercase {
  text-transform: lowercase
}

.initialism,
.text-uppercase {
  text-transform: uppercase
}

.text-capitalize {
  text-transform: capitalize
}

.text-muted {
  color: #777777
}

.text-primary {
  color: #337ab7
}

a.text-primary:focus,
a.text-primary:hover {
  color: #286090
}

.text-success {
  color: #3c763d
}

a.text-success:focus,
a.text-success:hover {
  color: #2b542c
}

.text-info {
  color: #31708f
}

a.text-info:focus,
a.text-info:hover {
  color: #245269
}

.text-warning {
  color: #8a6d3b
}

a.text-warning:focus,
a.text-warning:hover {
  color: #66512c
}

.text-danger {
  color: #a94442
}

a.text-danger:focus,
a.text-danger:hover {
  color: #843534
}

.bg-primary {
  color: #fff
}

.bg-primary {
  background-color: #337ab7
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #286090
}

.bg-success {
  background-color: #dff0d8
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #c1e2b3
}

.bg-info {
  background-color: #d9edf7
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #afd9ee
}

.bg-warning {
  background-color: #fcf8e3
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #f7ecb5
}

.bg-danger {
  background-color: #f2dede
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #e4b9b9
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px
}

.list-inline>li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px
}

dl {
  margin-top: 0;
  margin-bottom: 20px
}

dd,
dt {
  line-height: 1.42857
}

dt {
  font-weight: bold
}

dd {
  margin-left: 0
}

.dl-horizontal dd:after,
.dl-horizontal dd:before {
  content: " ";
  display: table
}

.dl-horizontal dd:after {
  clear: both
}

@media (min-width:768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
  }

  .dl-horizontal dd {
    margin-left: 180px
  }
}

abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777777
}

.initialism {
  font-size: 90%
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
  margin-bottom: 0
}

blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857;
  color: #777777
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: '\2014 \00A0'
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eeeeee;
  border-left: 0;
  text-align: right
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
  content: ''
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
  content: '\00A0 \2014'
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25)
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 0
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px
}

.container:after,
.container:before {
  content: " ";
  display: table
}

.container:after {
  clear: both
}

@media (min-width:768px) {
  .container {
    width: 750px
  }
}

@media (min-width:992px) {
  .container {
    width: 970px
  }
}

@media (min-width:1200px) {
  .container {
    width: 1060px
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px
}

.container-fluid:after,
.container-fluid:before {
  content: " ";
  display: table
}

.container-fluid:after {
  clear: both
}

.row {
  margin-left: -15px;
  margin-right: -15px
}

.row:after,
.row:before {
  content: " ";
  display: table
}

.row:after {
  clear: both
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left
}

.col-xs-1 {
  width: 8.33333%
}

.col-xs-2 {
  width: 16.66667%
}

.col-xs-3 {
  width: 25%
}

.col-xs-4 {
  width: 33.33333%
}

.col-xs-5 {
  width: 41.66667%
}

.col-xs-6 {
  width: 50%
}

.col-xs-7 {
  width: 58.33333%
}

.col-xs-8 {
  width: 66.66667%
}

.col-xs-9 {
  width: 75%
}

.col-xs-10 {
  width: 83.33333%
}

.col-xs-11 {
  width: 91.66667%
}

.col-xs-12 {
  width: 100%
}

.col-xs-pull-0 {
  right: auto
}

.col-xs-pull-1 {
  right: 8.33333%
}

.col-xs-pull-2 {
  right: 16.66667%
}

.col-xs-pull-3 {
  right: 25%
}

.col-xs-pull-4 {
  right: 33.33333%
}

.col-xs-pull-5 {
  right: 41.66667%
}

.col-xs-pull-6 {
  right: 50%
}

.col-xs-pull-7 {
  right: 58.33333%
}

.col-xs-pull-8 {
  right: 66.66667%
}

.col-xs-pull-9 {
  right: 75%
}

.col-xs-pull-10 {
  right: 83.33333%
}

.col-xs-pull-11 {
  right: 91.66667%
}

.col-xs-pull-12 {
  right: 100%
}

.col-xs-push-0 {
  left: auto
}

.col-xs-push-1 {
  left: 8.33333%
}

.col-xs-push-2 {
  left: 16.66667%
}

.col-xs-push-3 {
  left: 25%
}

.col-xs-push-4 {
  left: 33.33333%
}

.col-xs-push-5 {
  left: 41.66667%
}

.col-xs-push-6 {
  left: 50%
}

.col-xs-push-7 {
  left: 58.33333%
}

.col-xs-push-8 {
  left: 66.66667%
}

.col-xs-push-9 {
  left: 75%
}

.col-xs-push-10 {
  left: 83.33333%
}

.col-xs-push-11 {
  left: 91.66667%
}

.col-xs-push-12 {
  left: 100%
}

.col-xs-offset-0 {
  margin-left: 0%
}

.col-xs-offset-1 {
  margin-left: 8.33333%
}

.col-xs-offset-2 {
  margin-left: 16.66667%
}

.col-xs-offset-3 {
  margin-left: 25%
}

.col-xs-offset-4 {
  margin-left: 33.33333%
}

.col-xs-offset-5 {
  margin-left: 41.66667%
}

.col-xs-offset-6 {
  margin-left: 50%
}

.col-xs-offset-7 {
  margin-left: 58.33333%
}

.col-xs-offset-8 {
  margin-left: 66.66667%
}

.col-xs-offset-9 {
  margin-left: 75%
}

.col-xs-offset-10 {
  margin-left: 83.33333%
}

.col-xs-offset-11 {
  margin-left: 91.66667%
}

.col-xs-offset-12 {
  margin-left: 100%
}

@media (min-width:768px) {

  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left
  }

  .col-sm-1 {
    width: 8.33333%
  }

  .col-sm-2 {
    width: 16.66667%
  }

  .col-sm-3 {
    width: 25%
  }

  .col-sm-4 {
    width: 33.33333%
  }

  .col-sm-5 {
    width: 41.66667%
  }

  .col-sm-6 {
    width: 50%
  }

  .col-sm-7 {
    width: 58.33333%
  }

  .col-sm-8 {
    width: 66.66667%
  }

  .col-sm-9 {
    width: 75%
  }

  .col-sm-10 {
    width: 83.33333%
  }

  .col-sm-11 {
    width: 91.66667%
  }

  .col-sm-12 {
    width: 100%
  }

  .col-sm-pull-0 {
    right: auto
  }

  .col-sm-pull-1 {
    right: 8.33333%
  }

  .col-sm-pull-2 {
    right: 16.66667%
  }

  .col-sm-pull-3 {
    right: 25%
  }

  .col-sm-pull-4 {
    right: 33.33333%
  }

  .col-sm-pull-5 {
    right: 41.66667%
  }

  .col-sm-pull-6 {
    right: 50%
  }

  .col-sm-pull-7 {
    right: 58.33333%
  }

  .col-sm-pull-8 {
    right: 66.66667%
  }

  .col-sm-pull-9 {
    right: 75%
  }

  .col-sm-pull-10 {
    right: 83.33333%
  }

  .col-sm-pull-11 {
    right: 91.66667%
  }

  .col-sm-pull-12 {
    right: 100%
  }

  .col-sm-push-0 {
    left: auto
  }

  .col-sm-push-1 {
    left: 8.33333%
  }

  .col-sm-push-2 {
    left: 16.66667%
  }

  .col-sm-push-3 {
    left: 25%
  }

  .col-sm-push-4 {
    left: 33.33333%
  }

  .col-sm-push-5 {
    left: 41.66667%
  }

  .col-sm-push-6 {
    left: 50%
  }

  .col-sm-push-7 {
    left: 58.33333%
  }

  .col-sm-push-8 {
    left: 66.66667%
  }

  .col-sm-push-9 {
    left: 75%
  }

  .col-sm-push-10 {
    left: 83.33333%
  }

  .col-sm-push-11 {
    left: 91.66667%
  }

  .col-sm-push-12 {
    left: 100%
  }

  .col-sm-offset-0 {
    margin-left: 0%
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%
  }

  .col-sm-offset-3 {
    margin-left: 25%
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%
  }

  .col-sm-offset-6 {
    margin-left: 50%
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%
  }

  .col-sm-offset-9 {
    margin-left: 75%
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%
  }

  .col-sm-offset-12 {
    margin-left: 100%
  }
}

@media (min-width:992px) {

  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left
  }

  .col-md-1 {
    width: 8.33333%
  }

  .col-md-2 {
    width: 16.66667%
  }

  .col-md-3 {
    width: 25%
  }

  .col-md-4 {
    width: 33.33333%
  }

  .col-md-5 {
    width: 41.66667%
  }

  .col-md-6 {
    width: 50%
  }

  .col-md-7 {
    width: 58.33333%
  }

  .col-md-8 {
    width: 66.66667%
  }

  .col-md-9 {
    width: 75%
  }

  .col-md-10 {
    width: 83.33333%
  }

  .col-md-11 {
    width: 91.66667%
  }

  .col-md-12 {
    width: 100%
  }

  .col-md-pull-0 {
    right: auto
  }

  .col-md-pull-1 {
    right: 8.33333%
  }

  .col-md-pull-2 {
    right: 16.66667%
  }

  .col-md-pull-3 {
    right: 25%
  }

  .col-md-pull-4 {
    right: 33.33333%
  }

  .col-md-pull-5 {
    right: 41.66667%
  }

  .col-md-pull-6 {
    right: 50%
  }

  .col-md-pull-7 {
    right: 58.33333%
  }

  .col-md-pull-8 {
    right: 66.66667%
  }

  .col-md-pull-9 {
    right: 75%
  }

  .col-md-pull-10 {
    right: 83.33333%
  }

  .col-md-pull-11 {
    right: 91.66667%
  }

  .col-md-pull-12 {
    right: 100%
  }

  .col-md-push-0 {
    left: auto
  }

  .col-md-push-1 {
    left: 8.33333%
  }

  .col-md-push-2 {
    left: 16.66667%
  }

  .col-md-push-3 {
    left: 25%
  }

  .col-md-push-4 {
    left: 33.33333%
  }

  .col-md-push-5 {
    left: 41.66667%
  }

  .col-md-push-6 {
    left: 50%
  }

  .col-md-push-7 {
    left: 58.33333%
  }

  .col-md-push-8 {
    left: 66.66667%
  }

  .col-md-push-9 {
    left: 75%
  }

  .col-md-push-10 {
    left: 83.33333%
  }

  .col-md-push-11 {
    left: 91.66667%
  }

  .col-md-push-12 {
    left: 100%
  }

  .col-md-offset-0 {
    margin-left: 0%
  }

  .col-md-offset-1 {
    margin-left: 8.33333%
  }

  .col-md-offset-2 {
    margin-left: 16.66667%
  }

  .col-md-offset-3 {
    margin-left: 25%
  }

  .col-md-offset-4 {
    margin-left: 33.33333%
  }

  .col-md-offset-5 {
    margin-left: 41.66667%
  }

  .col-md-offset-6 {
    margin-left: 50%
  }

  .col-md-offset-7 {
    margin-left: 58.33333%
  }

  .col-md-offset-8 {
    margin-left: 66.66667%
  }

  .col-md-offset-9 {
    margin-left: 75%
  }

  .col-md-offset-10 {
    margin-left: 83.33333%
  }

  .col-md-offset-11 {
    margin-left: 91.66667%
  }

  .col-md-offset-12 {
    margin-left: 100%
  }
}

@media (min-width:1200px) {

  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left
  }

  .col-lg-1 {
    width: 8.33333%
  }

  .col-lg-2 {
    width: 16.66667%
  }

  .col-lg-3 {
    width: 25%
  }

  .col-lg-4 {
    width: 33.33333%
  }

  .col-lg-5 {
    width: 41.66667%
  }

  .col-lg-6 {
    width: 50%
  }

  .col-lg-7 {
    width: 58.33333%
  }

  .col-lg-8 {
    width: 66.66667%
  }

  .col-lg-9 {
    width: 75%
  }

  .col-lg-10 {
    width: 83.33333%
  }

  .col-lg-11 {
    width: 91.66667%
  }

  .col-lg-12 {
    width: 100%
  }

  .col-lg-pull-0 {
    right: auto
  }

  .col-lg-pull-1 {
    right: 8.33333%
  }

  .col-lg-pull-2 {
    right: 16.66667%
  }

  .col-lg-pull-3 {
    right: 25%
  }

  .col-lg-pull-4 {
    right: 33.33333%
  }

  .col-lg-pull-5 {
    right: 41.66667%
  }

  .col-lg-pull-6 {
    right: 50%
  }

  .col-lg-pull-7 {
    right: 58.33333%
  }

  .col-lg-pull-8 {
    right: 66.66667%
  }

  .col-lg-pull-9 {
    right: 75%
  }

  .col-lg-pull-10 {
    right: 83.33333%
  }

  .col-lg-pull-11 {
    right: 91.66667%
  }

  .col-lg-pull-12 {
    right: 100%
  }

  .col-lg-push-0 {
    left: auto
  }

  .col-lg-push-1 {
    left: 8.33333%
  }

  .col-lg-push-2 {
    left: 16.66667%
  }

  .col-lg-push-3 {
    left: 25%
  }

  .col-lg-push-4 {
    left: 33.33333%
  }

  .col-lg-push-5 {
    left: 41.66667%
  }

  .col-lg-push-6 {
    left: 50%
  }

  .col-lg-push-7 {
    left: 58.33333%
  }

  .col-lg-push-8 {
    left: 66.66667%
  }

  .col-lg-push-9 {
    left: 75%
  }

  .col-lg-push-10 {
    left: 83.33333%
  }

  .col-lg-push-11 {
    left: 91.66667%
  }

  .col-lg-push-12 {
    left: 100%
  }

  .col-lg-offset-0 {
    margin-left: 0%
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%
  }

  .col-lg-offset-3 {
    margin-left: 25%
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%
  }

  .col-lg-offset-6 {
    margin-left: 50%
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%
  }

  .col-lg-offset-9 {
    margin-left: 75%
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%
  }

  .col-lg-offset-12 {
    margin-left: 100%
  }
}

table {
  background-color: transparent
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777777;
  text-align: left
}

th {
  text-align: left
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
  border-top: 0
}

.table>tbody+tbody {
  border-top: 2px solid #ddd
}

.table .table {
  background-color: #fff
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  padding: 5px
}

.table-bordered {
  border: 1px solid #ddd
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border: 1px solid #ddd
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border-bottom-width: 2px
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9
}

.table-hover>tbody>tr:hover {
  background-color: #f5f5f5
}

table col[class*=col-] {
  position: static;
  float: none;
  display: table-column
}

table td[class*=col-],
table th[class*=col-] {
  position: static;
  float: none;
  display: table-cell
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
  background-color: #f5f5f5
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
  background-color: #e8e8e8
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
  background-color: #dff0d8
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
  background-color: #d0e9c6
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
  background-color: #d9edf7
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
  background-color: #c4e3f3
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
  background-color: #fcf8e3
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
  background-color: #faf2cc
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
  background-color: #f2dede
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
  background-color: #ebcccc
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%
}

@media screen and (max-width:767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd
  }

  .table-responsive>.table {
    margin-bottom: 0
  }

  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>td,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>thead>tr>th {
    white-space: nowrap
  }

  .table-responsive>.table-bordered {
    border: 0
  }

  .table-responsive>.table-bordered>tbody>tr>td:first-child,
  .table-responsive>.table-bordered>tbody>tr>th:first-child,
  .table-responsive>.table-bordered>tfoot>tr>td:first-child,
  .table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .table-responsive>.table-bordered>thead>tr>td:first-child,
  .table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left: 0
  }

  .table-responsive>.table-bordered>tbody>tr>td:last-child,
  .table-responsive>.table-bordered>tbody>tr>th:last-child,
  .table-responsive>.table-bordered>tfoot>tr>td:last-child,
  .table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .table-responsive>.table-bordered>thead>tr>td:last-child,
  .table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right: 0
  }

  .table-responsive>.table-bordered>tbody>tr:last-child>td,
  .table-responsive>.table-bordered>tbody>tr:last-child>th,
  .table-responsive>.table-bordered>tfoot>tr:last-child>td,
  .table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold
}

input[type=search] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box
}

input[type=checkbox],
input[type=radio] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal
}

input[type=file] {
  display: block
}

input[type=range] {
  display: block;
  width: 100%
}

select[multiple],
select[size] {
  height: auto
}

input[type=checkbox]:focus,
input[type=file]:focus,
input[type=radio]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

output {
  display: block;
  padding-top: 11px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555
}

.form-control {
  display: block;
  width: 100%;
  height: 42px;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.42857;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1
}

.form-control:-ms-input-placeholder {
  color: #999
}

.form-control::-webkit-input-placeholder {
  color: #999
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eeeeee;
  opacity: 1
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed
}

textarea.form-control {
  height: auto
}

input[type=search] {
  -webkit-appearance: none
}

@media screen and (-webkit-min-device-pixel-ratio:0) {

  input[type=date].form-control,
  input[type=datetime-local].form-control,
  input[type=month].form-control,
  input[type=time].form-control {
    line-height: 42px
  }

  .input-group-sm>.input-group-btn>input.btn[type=date],
  .input-group-sm>.input-group-btn>input.btn[type=datetime-local],
  .input-group-sm>.input-group-btn>input.btn[type=month],
  .input-group-sm>.input-group-btn>input.btn[type=time],
  .input-group-sm>input.form-control[type=date],
  .input-group-sm>input.form-control[type=datetime-local],
  .input-group-sm>input.form-control[type=month],
  .input-group-sm>input.form-control[type=time],
  .input-group-sm>input.input-group-addon[type=date],
  .input-group-sm>input.input-group-addon[type=datetime-local],
  .input-group-sm>input.input-group-addon[type=month],
  .input-group-sm>input.input-group-addon[type=time],
  .input-group-sm input[type=date],
  .input-group-sm input[type=datetime-local],
  .input-group-sm input[type=month],
  .input-group-sm input[type=time],
  input[type=date].input-sm,
  input[type=datetime-local].input-sm,
  input[type=month].input-sm,
  input[type=time].input-sm {
    line-height: 30px
  }

  .input-group-lg>.input-group-btn>input.btn[type=date],
  .input-group-lg>.input-group-btn>input.btn[type=datetime-local],
  .input-group-lg>.input-group-btn>input.btn[type=month],
  .input-group-lg>.input-group-btn>input.btn[type=time],
  .input-group-lg>input.form-control[type=date],
  .input-group-lg>input.form-control[type=datetime-local],
  .input-group-lg>input.form-control[type=month],
  .input-group-lg>input.form-control[type=time],
  .input-group-lg>input.input-group-addon[type=date],
  .input-group-lg>input.input-group-addon[type=datetime-local],
  .input-group-lg>input.input-group-addon[type=month],
  .input-group-lg>input.input-group-addon[type=time],
  .input-group-lg input[type=date],
  .input-group-lg input[type=datetime-local],
  .input-group-lg input[type=month],
  .input-group-lg input[type=time],
  input[type=date].input-lg,
  input[type=datetime-local].input-lg,
  input[type=month].input-lg,
  input[type=time].input-lg {
    line-height: 46px
  }
}

.form-group {
  margin-bottom: 15px
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px
}

.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer
}

.checkbox-inline input[type=checkbox],
.checkbox input[type=checkbox],
.radio-inline input[type=radio],
.radio input[type=radio] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px\9
}

.checkbox+.checkbox,
.radio+.radio {
  margin-top: -5px
}

.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
  margin-top: 0;
  margin-left: 10px
}

fieldset[disabled] input[type=checkbox],
fieldset[disabled] input[type=radio],
input[type=checkbox].disabled,
input[type=checkbox][disabled],
input[type=radio].disabled,
input[type=radio][disabled] {
  cursor: not-allowed
}

.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
  cursor: not-allowed
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  cursor: not-allowed
}

.form-control-static {
  padding-top: 11px;
  padding-bottom: 11px;
  margin-bottom: 0;
  min-height: 34px
}

.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
  padding-left: 0;
  padding-right: 0
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0
}

.input-group-sm>.input-group-btn>select.btn,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
select.input-sm {
  height: 30px;
  line-height: 30px
}

.input-group-sm>.input-group-btn>select.btn[multiple],
.input-group-sm>.input-group-btn>textarea.btn,
.input-group-sm>select.form-control[multiple],
.input-group-sm>select.input-group-addon[multiple],
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
select[multiple].input-sm,
textarea.input-sm {
  height: auto
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
  height: auto
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 0
}

.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
  height: 46px;
  line-height: 46px
}

.input-group-lg>.input-group-btn>select.btn[multiple],
.input-group-lg>.input-group-btn>textarea.btn,
.input-group-lg>select.form-control[multiple],
.input-group-lg>select.input-group-addon[multiple],
.input-group-lg>textarea.form-control,
.input-group-lg>textarea.input-group-addon,
select[multiple].input-lg,
textarea.input-lg {
  height: auto
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 0
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
  height: auto
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.33333
}

.has-feedback {
  position: relative
}

.has-feedback .form-control {
  padding-right: 52.5px
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 42px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  pointer-events: none
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-lg+.form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-sm+.form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success.checkbox-inline label,
.has-success.checkbox label,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.radio-inline label,
.has-success.radio label {
  color: #3c763d
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168
}

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8
}

.has-success .form-control-feedback {
  color: #3c763d
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning.checkbox-inline label,
.has-warning.checkbox label,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.radio-inline label,
.has-warning.radio label {
  color: #8a6d3b
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3
}

.has-warning .form-control-feedback {
  color: #8a6d3b
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error.checkbox-inline label,
.has-error.checkbox label,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.radio-inline label,
.has-error.radio label {
  color: #a94442
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483
}

.has-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede
}

.has-error .form-control-feedback {
  color: #a94442
}

.has-feedback label~.form-control-feedback {
  top: 25px
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 0
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373
}

@media (min-width:768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-static {
    display: inline-block
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle
  }

  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto
  }

  .form-inline .input-group>.form-control {
    width: 100%
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .checkbox label,
  .form-inline .radio label {
    padding-left: 0
  }

  .form-inline .checkbox input[type=checkbox],
  .form-inline .radio input[type=radio] {
    position: relative;
    margin-left: 0
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0
  }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 11px
}

.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 31px
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px
}

.form-horizontal .form-group:after,
.form-horizontal .form-group:before {
  content: " ";
  display: table
}

.form-horizontal .form-group:after {
  clear: both
}

@media (min-width:768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 11px
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px
}

@media (min-width:768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 14.33333px;
    font-size: 18px
  }
}

@media (min-width:768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 1.42857;
  border-radius: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: black;
  text-decoration: none
}

.btn.active,
.btn:active {
  outline: 0;
  background-image: none;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none
}

.btn-default {
  color: black;
  background-color: #fff;
  border-color: black;
  border-radius: 4px;
}

.btn-default.focus,
.btn-default:focus {
  color: black;
  background-color: #e6e6e6;
  border-color: #0e4764
}

.btn-default:hover {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 0px 1px #8080ff;
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 0px 2px #2bc275;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.btn-default.dropdown-toggle.focus,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle:hover {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 0px 2px #2bc275;
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle {
  background-image: none
}

.btn-default.disabled,
.btn-default.disabled.active,
.btn-default.disabled.focus,
.btn-default.disabled:active,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled],
.btn-default[disabled].active,
.btn-default[disabled].focus,
.btn-default[disabled]:active,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default.active,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #1e97d4
}

.btn-default .badge {
  color: #fff;
  background-color: #1e97d4
}

.btn-outline-primary {
  color: black;
  background-color: white;
  border-color: black;
  border-radius: 4px;
}
.btn-outline-primary:hover {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 0px 1px #8080ff;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #1e97d4;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: black;
  background-color: white;
  box-shadow: 0px 0px 0px 2px #2bc275;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0px 0px 0px 2px #2bc275;
}

.btn-primary {
  color: #fff;
  background-color: #000;
  border-radius: 4px;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #1877a7;
  border-color: #0e4764
}

.btn-primary:hover {
  color: #fff;
  background-color: #000;
  box-shadow: 0px 0px 0px 1px #8080ff;
}

.btn-primary.active,
.btn-primary:active,
.open>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: black;
  box-shadow: 0px 0px 0px 2px #2bc275;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.btn-primary.dropdown-toggle.focus,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle:hover {
  color: #fff;
  background-color: black;
}

.btn-primary.active,
.btn-primary:active,
.open>.btn-primary.dropdown-toggle {
  background-image: none
}

.btn-primary.disabled,
.btn-primary.disabled.active,
.btn-primary.disabled.focus,
.btn-primary.disabled:active,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled],
.btn-primary[disabled].active,
.btn-primary[disabled].focus,
.btn-primary[disabled]:active,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary.active,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #1e97d4;
  border-color: #1e97d4
}

.btn-primary .badge {
  color: #1e97d4;
  background-color: #fff
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439
}

.btn-success.active,
.btn-success:active,
.open>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #398439
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.btn-success.dropdown-toggle.focus,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625
}

.btn-success.active,
.btn-success:active,
.open>.btn-success.dropdown-toggle {
  background-image: none
}

.btn-success.disabled,
.btn-success.disabled.active,
.btn-success.disabled.focus,
.btn-success.disabled:active,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled],
.btn-success[disabled].active,
.btn-success[disabled].focus,
.btn-success[disabled]:active,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success.active,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc
}

.btn-info.active,
.btn-info:active,
.open>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.btn-info.dropdown-toggle.focus,
.open>.btn-info.dropdown-toggle:focus,
.open>.btn-info.dropdown-toggle:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85
}

.btn-info.active,
.btn-info:active,
.open>.btn-info.dropdown-toggle {
  background-image: none
}

.btn-info.disabled,
.btn-info.disabled.active,
.btn-info.disabled.focus,
.btn-info.disabled:active,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled],
.btn-info[disabled].active,
.btn-info[disabled].focus,
.btn-info[disabled]:active,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info.active,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236
}

.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512
}

.btn-warning.active,
.btn-warning:active,
.open>.btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.btn-warning.dropdown-toggle.focus,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d
}

.btn-warning.active,
.btn-warning:active,
.open>.btn-warning.dropdown-toggle {
  background-image: none
}

.btn-warning.disabled,
.btn-warning.disabled.active,
.btn-warning.disabled.focus,
.btn-warning.disabled:active,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled],
.btn-warning[disabled].active,
.btn-warning[disabled].focus,
.btn-warning[disabled]:active,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning.active,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925
}

.btn-danger.active,
.btn-danger:active,
.open>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.btn-danger.dropdown-toggle.focus,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19
}

.btn-danger.active,
.btn-danger:active,
.open>.btn-danger.dropdown-toggle {
  background-image: none
}

.btn-danger.disabled,
.btn-danger.disabled.active,
.btn-danger.disabled.focus,
.btn-danger.disabled:active,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled],
.btn-danger[disabled].active,
.btn-danger[disabled].focus,
.btn-danger[disabled]:active,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger.active,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff
}

.btn-link {
  color: #1e97d4;
  font-weight: normal;
  border-radius: 0
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent
}

.btn-link:focus,
.btn-link:hover {
  color: #1e97d4;
  text-decoration: underline;
  background-color: transparent
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #777777;
  text-decoration: none
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333;
  border-radius: 0
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block+.btn-block {
  margin-top: 5px
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
  width: 100%
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear
}

.fade.in {
  opacity: 1
}

.collapse {
  display: none
}

.collapse.in {
  display: block
}

tr.collapse.in {
  display: table-row
}

tbody.collapse.in {
  display: table-row-group
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent
}

.dropdown,
.dropup {
  position: relative
}

.dropdown-toggle:focus {
  outline: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857;
  color: #333333;
  white-space: nowrap
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
  color: #777777
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed
}

.open>.dropdown-menu {
  display: block
}

.open>a {
  outline: 0
}

.dropdown-menu-right {
  left: auto;
  right: 0
}

.dropdown-menu-left {
  left: 0;
  right: auto
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857;
  color: #777777;
  white-space: nowrap
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
  content: ""
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px
}

@media (min-width:768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  float: left
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 2
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: -1px
}

.btn-toolbar {
  margin-left: -5px
}

.btn-toolbar:after,
.btn-toolbar:before {
  content: " ";
  display: table
}

.btn-toolbar:after {
  clear: both
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
  margin-left: 5px
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0
}

.btn-group>.btn:first-child {
  margin-left: 0
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.btn-group>.btn-group {
  float: left
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0
}

.btn-group>.btn+.dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px
}

.btn-group-lg.btn-group>.btn+.dropdown-toggle,
.btn-group>.btn-lg+.dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none
}

.btn .caret {
  margin-left: 0
}

.btn-group-lg>.btn .caret,
.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0
}

.dropup .btn-group-lg>.btn .caret,
.dropup .btn-lg .caret {
  border-width: 0 5px 5px
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before {
  content: " ";
  display: table
}

.btn-group-vertical>.btn-group:after {
  clear: both
}

.btn-group-vertical>.btn-group>.btn {
  float: none
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
  border-radius: 0
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
  float: none;
  display: table-cell;
  width: 1%
}

.btn-group-justified>.btn-group .btn {
  width: 100%
}

.btn-group-justified>.btn-group .dropdown-menu {
  left: auto
}

[data-toggle=buttons]>.btn-group>.btn input[type=checkbox],
[data-toggle=buttons]>.btn-group>.btn input[type=radio],
[data-toggle=buttons]>.btn input[type=checkbox],
[data-toggle=buttons]>.btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate
}

.input-group[class*=col-] {
  float: none;
  padding-left: 0;
  padding-right: 0
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
  display: table-cell
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle
}

.input-group-addon {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #ccc;
  border-radius: 0
}

.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 0
}

.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 0
}

.input-group-addon input[type=checkbox],
.input-group-addon input[type=radio] {
  margin-top: 0
}

.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group .form-control:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.input-group-addon:first-child {
  border-right: 0
}

.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle,
.input-group .form-control:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.input-group-addon:last-child {
  border-left: 0
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap
}

.input-group-btn>.btn {
  position: relative
}

.input-group-btn>.btn+.btn {
  margin-left: -1px
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
  z-index: 2
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
  margin-right: -1px
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  z-index: 2;
  margin-left: -1px
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none
}

.nav:after,
.nav:before {
  content: " ";
  display: table
}

.nav:after {
  clear: both
}

.nav>li {
  position: relative;
  display: block
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px
}

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: #eeeeee
}

.nav>li.disabled>a {
  color: #777777
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
  color: #777777;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: #eeeeee;
  border-color: #1e97d4
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.nav>li>a>img {
  max-width: none
}

.nav-tabs {
  border-bottom: 1px solid #ddd
}

.nav-tabs>li {
  float: left;
  margin-bottom: -1px
}

.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857;
  border: 1px solid transparent;
  border-radius: 0 0 0 0
}

.nav-tabs>li>a:hover {
  border-color: #eeeeee #eeeeee #ddd
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: #555555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default
}

.nav-pills>li {
  float: left
}

.nav-pills>li>a {
  border-radius: 0
}

.nav-pills>li+li {
  margin-left: 2px
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #337ab7
}

.nav-stacked>li {
  float: none
}

.nav-stacked>li+li {
  margin-top: 2px;
  margin-left: 0
}

.nav-justified,
.nav-tabs.nav-justified {
  width: 100%
}

.nav-justified>li,
.nav-tabs.nav-justified>li {
  float: none
}

.nav-justified>li>a,
.nav-tabs.nav-justified>li>a {
  text-align: center;
  margin-bottom: 5px
}

.nav-justified>.dropdown .dropdown-menu {
  top: auto;
  left: auto
}

@media (min-width:768px) {

  .nav-justified>li,
  .nav-tabs.nav-justified>li {
    display: table-cell;
    width: 1%
  }

  .nav-justified>li>a,
  .nav-tabs.nav-justified>li>a {
    margin-bottom: 0
  }
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
  border-bottom: 0
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
  margin-right: 0;
  border-radius: 0
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover {
  border: 1px solid #ddd
}

@media (min-width:768px) {

  .nav-tabs-justified>li>a,
  .nav-tabs.nav-justified>li>a {
    border-bottom: 1px solid #ddd;
    border-radius: 0 0 0 0
  }

  .nav-tabs-justified>.active>a,
  .nav-tabs-justified>.active>a:focus,
  .nav-tabs-justified>.active>a:hover,
  .nav-tabs.nav-justified>.active>a,
  .nav-tabs.nav-justified>.active>a:focus,
  .nav-tabs.nav-justified>.active>a:hover {
    border-bottom-color: #fff
  }
}

.tab-content>.tab-pane {
  display: none
}

.tab-content>.active {
  display: block
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent
}

.navbar:after,
.navbar:before {
  content: " ";
  display: table
}

.navbar:after {
  clear: both
}

@media (min-width:768px) {
  .navbar {
    border-radius: 0
  }
}

.navbar-header:after,
.navbar-header:before {
  content: " ";
  display: table
}

.navbar-header:after {
  clear: both
}

@media (min-width:768px) {
  .navbar-header {
    float: left
  }
}

.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch
}

.navbar-collapse:after,
.navbar-collapse:before {
  content: " ";
  display: table
}

.navbar-collapse:after {
  clear: both
}

.navbar-collapse.in {
  overflow-y: auto
}

@media (min-width:768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important
  }

  .navbar-collapse.in {
    overflow-y: visible
  }

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse {
    padding-left: 0;
    padding-right: 0
  }
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: 340px
}

@media (max-device-width:480px) and (orientation:landscape) {

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px
  }
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
  margin-right: -15px;
  margin-left: -15px
}

@media (min-width:768px) {

  .container-fluid>.navbar-collapse,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container>.navbar-header {
    margin-right: 0;
    margin-left: 0
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px
}

@media (min-width:768px) {
  .navbar-static-top {
    border-radius: 0
  }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030
}

@media (min-width:768px) {

  .navbar-fixed-bottom,
  .navbar-fixed-top {
    border-radius: 0
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0
}

.navbar-brand {
  float: left;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
  height: 50px
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none
}

.navbar-brand>img {
  display: block
}

@media (min-width:768px) {

  .navbar>.container-fluid .navbar-brand,
  .navbar>.container .navbar-brand {
    margin-left: -15px
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0
}

.navbar-toggle:focus {
  outline: 0
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px
}

@media (min-width:768px) {
  .navbar-toggle {
    display: none
  }
}

.navbar-nav {
  margin: 7.5px -15px
}

.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px
}

@media (max-width:767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none
  }

  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 15px 5px 25px
  }

  .navbar-nav .open .dropdown-menu>li>a {
    line-height: 20px
  }

  .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-nav .open .dropdown-menu>li>a:hover {
    background-image: none
  }
}

@media (min-width:768px) {
  .navbar-nav {
    float: left;
    margin: 0
  }

  .navbar-nav>li {
    float: left
  }

  .navbar-nav>li>a {
    padding-top: 15px;
    padding-bottom: 15px
  }
}

.navbar-form {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 4px;
  margin-bottom: 4px
}

@media (min-width:768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .navbar-form .form-control-static {
    display: inline-block
  }

  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle
  }

  .navbar-form .input-group .form-control,
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn {
    width: auto
  }

  .navbar-form .input-group>.form-control {
    width: 100%
  }

  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .checkbox,
  .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .checkbox label,
  .navbar-form .radio label {
    padding-left: 0
  }

  .navbar-form .checkbox input[type=checkbox],
  .navbar-form .radio input[type=radio] {
    position: relative;
    margin-left: 0
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0
  }
}

@media (max-width:767px) {
  .navbar-form .form-group {
    margin-bottom: 5px
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0
  }
}

@media (min-width:768px) {
  .navbar-form {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none
  }
}

.navbar-nav>li>.dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.navbar-btn {
  margin-top: 4px;
  margin-bottom: 4px
}

.btn-group-sm>.navbar-btn.btn,
.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px
}

.btn-group-xs>.navbar-btn.btn,
.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px
}

@media (min-width:768px) {
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px
  }
}

@media (min-width:768px) {
  .navbar-left {
    float: left !important
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px
  }

  .navbar-right~.navbar-right {
    margin-right: 0
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7
}

.navbar-default .navbar-brand {
  color: #777
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent
}

.navbar-default .navbar-text {
  color: #777
}

.navbar-default .navbar-nav>li>a {
  color: #777
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #333;
  background-color: transparent
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
  color: #555;
  background-color: #e7e7e7
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
  color: #ccc;
  background-color: transparent
}

.navbar-default .navbar-toggle {
  border-color: #ddd
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  background-color: #e7e7e7;
  color: #555
}

@media (max-width:767px) {
  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #777
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #333;
    background-color: transparent
  }

  .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #555;
    background-color: #e7e7e7
  }

  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
    color: #ccc;
    background-color: transparent
  }
}

.navbar-default .navbar-link {
  color: #777
}

.navbar-default .navbar-link:hover {
  color: #333
}

.navbar-default .btn-link {
  color: #777
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
  color: #333
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc
}

.navbar-inverse {
  background-color: #222;
  border-color: #090909
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent
}

.navbar-inverse .navbar-text {
  color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a {
  color: #9d9d9d
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
  color: #fff;
  background-color: transparent
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: #090909
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
  color: #444;
  background-color: transparent
}

.navbar-inverse .navbar-toggle {
  border-color: #333
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
  background-color: #090909;
  color: #fff
}

@media (max-width:767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
    border-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #9d9d9d
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #fff;
    background-color: transparent
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #fff;
    background-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
    color: #444;
    background-color: transparent
  }
}

.navbar-inverse .navbar-link {
  color: #9d9d9d
}

.navbar-inverse .navbar-link:hover {
  color: #fff
}

.navbar-inverse .btn-link {
  color: #9d9d9d
}

.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
  color: #fff
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 0
}

.breadcrumb>li {
  display: inline-block
}

.breadcrumb>li+li:before {
  content: "/ ";
  padding: 0 5px;
  color: #ccc
}

.breadcrumb>.active {
  color: #777777
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 0
}

.pagination>li {
  display: inline
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 10px 20px;
  line-height: 1.42857;
  text-decoration: none;
  color: #1e97d4;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  z-index: 3;
  color: #1e97d4;
  background-color: #eeeeee;
  border-color: #ddd
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
  cursor: default
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33333
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  list-style: none;
  text-align: center
}

.pager:after,
.pager:before {
  content: " ";
  display: table
}

.pager:after {
  clear: both
}

.pager li {
  display: inline
}

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px
}

.pager li>a:focus,
.pager li>a:hover {
  text-decoration: none;
  background-color: #eeeeee
}

.pager .next>a,
.pager .next>span {
  float: right
}

.pager .previous>a,
.pager .previous>span {
  float: left
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
  color: #777777;
  background-color: #fff;
  cursor: not-allowed
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em
}

.label:empty {
  display: none
}

.btn .label {
  position: relative;
  top: -1px
}

a.label:focus,
a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.label-default {
  background-color: #777777
}

.label-default[href]:focus,
.label-default[href]:hover {
  background-color: #5e5e5e
}

.label-primary {
  background-color: #337ab7
}

.label-primary[href]:focus,
.label-primary[href]:hover {
  background-color: #286090
}

.label-success {
  background-color: #5cb85c
}

.label-success[href]:focus,
.label-success[href]:hover {
  background-color: #449d44
}

.label-info {
  background-color: #5bc0de
}

.label-info[href]:focus,
.label-info[href]:hover {
  background-color: #31b0d5
}

.label-warning {
  background-color: #f0ad4e
}

.label-warning[href]:focus,
.label-warning[href]:hover {
  background-color: #ec971f
}

.label-danger {
  background-color: #d9534f
}

.label-danger[href]:focus,
.label-danger[href]:hover {
  background-color: #c9302c
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.btn-group-xs>.btn .badge,
.btn-group-xs>.btn .badge,
.btn-xs .badge {
  top: 0;
  padding: 1px 5px
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
  color: #1e97d4;
  background-color: #fff
}

.list-group-item>.badge {
  float: right
}

.list-group-item>.badge+.badge {
  margin-right: 5px
}

.nav-pills>li>a>.badge {
  margin-left: 3px
}

a.badge:focus,
a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eeeeee
}

.jumbotron .h1,
.jumbotron h1 {
  color: inherit
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200
}

.jumbotron>hr {
  border-top-color: #d5d5d5
}

.container-fluid .jumbotron,
.container .jumbotron {
  border-radius: 0
}

.jumbotron .container {
  max-width: 100%
}

@media screen and (min-width:768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px
  }

  .container-fluid .jumbotron,
  .container .jumbotron {
    padding-left: 60px;
    padding-right: 60px
  }

  .jumbotron .h1,
  .jumbotron h1 {
    font-size: 63px
  }
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out
}

.thumbnail>img,
.thumbnail a>img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto
}

.thumbnail .caption {
  padding: 9px;
  color: #333333
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #1e97d4
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 0
}

.alert h4 {
  margin-top: 0;
  color: inherit
}

.alert .alert-link {
  font-weight: bold
}

.alert>p,
.alert>ul {
  margin-bottom: 0
}

.alert>p+p {
  margin-top: 5px
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit
}

.alert-error {
  background-color: white;
  color: black;
  border: 1px solid lightgrey;
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: black;
}

.alert-success hr {
  border-top-color: #c9e2b3
}

.alert-success .alert-link {
  color: #2b542c
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f
}

.alert-info hr {
  border-top-color: #a6e1ec
}

.alert-info .alert-link {
  color: #245269
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b
}

.alert-warning hr {
  border-top-color: #f7e1b5
}

.alert-warning .alert-link {
  color: #66512c
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442
}

.alert-danger hr {
  border-top-color: #e4b9c0
}

.alert-danger .alert-link {
  color: #843534
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0
  }

  to {
    background-position: 0 0
  }
}

.progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px
}

.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
  background-color: #5cb85c
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-info {
  background-color: #5bc0de
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-warning {
  background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-danger {
  background-color: #d9534f
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.media {
  margin-top: 15px
}

.media:first-child {
  margin-top: 0
}

.media,
.media-body {
  zoom: 1;
  overflow: hidden
}

.media-body {
  width: 10000px
}

.media-object {
  display: block
}

.media-object.img-thumbnail {
  max-width: none
}

.media-right,
.media>.pull-right {
  padding-left: 10px
}

.media-left,
.media>.pull-left {
  padding-right: 10px
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top
}

.media-middle {
  vertical-align: middle
}

.media-bottom {
  vertical-align: bottom
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.media-list {
  padding-left: 0;
  list-style: none
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd
}

.list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

a.list-group-item,
button.list-group-item {
  color: #555
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  text-decoration: none;
  color: #555;
  background-color: #f5f5f5
}

button.list-group-item {
  width: 100%;
  text-align: left
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
  background-color: #eeeeee;
  color: #777777;
  cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
  color: #777777
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small {
  color: inherit
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #c7ddef
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8
}

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7
}

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05)
}

.panel-body {
  padding: 15px
}

.panel-body:after,
.panel-body:before {
  content: " ";
  display: table
}

.panel-body:after {
  clear: both
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: -1;
  border-top-left-radius: -1
}

.panel-heading>.dropdown .dropdown-toggle {
  color: inherit
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit
}

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
  color: inherit
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: -1;
  border-bottom-left-radius: -1
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
  margin-bottom: 0
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-right-radius: -1;
  border-top-left-radius: -1
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: -1;
  border-bottom-left-radius: -1
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.panel-heading+.list-group .list-group-item:first-child {
  border-top-width: 0
}

.list-group+.panel-footer {
  border-top-width: 0
}

.panel>.panel-collapse>.table,
.panel>.table,
.panel>.table-responsive>.table {
  margin-bottom: 0
}

.panel>.panel-collapse>.table caption,
.panel>.table-responsive>.table caption,
.panel>.table caption {
  padding-left: 15px;
  padding-right: 15px
}

.panel>.table-responsive:first-child>.table:first-child,
.panel>.table:first-child {
  border-top-right-radius: -1;
  border-top-left-radius: -1
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child {
  border-top-left-radius: -1;
  border-top-right-radius: -1
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
  border-top-left-radius: -1
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
  border-top-right-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child,
.panel>.table:last-child {
  border-bottom-right-radius: -1;
  border-bottom-left-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child {
  border-bottom-left-radius: -1;
  border-bottom-right-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
  border-bottom-left-radius: -1
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
  border-bottom-right-radius: -1
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
  border-top: 1px solid #ddd
}

.panel>.table>tbody:first-child>tr:first-child td,
.panel>.table>tbody:first-child>tr:first-child th {
  border-top: 0
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
  border: 0
}

.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
  border-left: 0
}

.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
  border-right: 0
}

.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
  border-bottom: 0
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
  border-bottom: 0
}

.panel>.table-responsive {
  border: 0;
  margin-bottom: 0
}

.panel-group {
  margin-bottom: 20px
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0
}

.panel-group .panel+.panel {
  margin-top: 5px
}

.panel-group .panel-heading {
  border-bottom: 0
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 1px solid #ddd
}

.panel-group .panel-footer {
  border-top: 0
}

.panel-group .panel-footer+.panel-collapse .panel-body {
  border-bottom: 1px solid #ddd
}

.panel-default {
  border-color: #ddd
}

.panel-default>.panel-heading {
  color: #333333;
  background-color: #f5f5f5;
  border-color: #ddd
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd
}

.panel-default>.panel-heading .badge {
  color: #f5f5f5;
  background-color: #333333
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ddd
}

.panel-primary {
  border-color: #337ab7
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #337ab7
}

.panel-primary>.panel-heading .badge {
  color: #337ab7;
  background-color: #fff
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #337ab7
}

.panel-success {
  border-color: #d6e9c6
}

.panel-success>.panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #d6e9c6
}

.panel-success>.panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #d6e9c6
}

.panel-info {
  border-color: #bce8f1
}

.panel-info>.panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #bce8f1
}

.panel-info>.panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #bce8f1
}

.panel-warning {
  border-color: #faebcc
}

.panel-warning>.panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #faebcc
}

.panel-warning>.panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #faebcc
}

.panel-danger {
  border-color: #ebccd1
}

.panel-danger>.panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ebccd1
}

.panel-danger>.panel-heading .badge {
  color: #f2dede;
  background-color: #a94442
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ebccd1
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%
}

.embed-responsive-4by3 {
  padding-bottom: 75%
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15)
}

.well-lg {
  padding: 24px;
  border-radius: 0
}

.well-sm {
  padding: 9px;
  border-radius: 0
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.2;
  filter: alpha(opacity=20)
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.5;
  filter: alpha(opacity=50)
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none
}

.modal-open {
  overflow: hidden
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0)
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0)
}

.modal-backdrop.in {
  opacity: 0.5;
  filter: alpha(opacity=50)
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857px
}

.modal-header .close {
  margin-top: -2px
}

.modal-title {
  margin: 0;
  line-height: 1.42857
}

.modal-body {
  position: relative;
  padding: 15px
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5
}

.modal-footer:after,
.modal-footer:before {
  content: " ";
  display: table
}

.modal-footer:after {
  clear: both
}

.modal-footer .btn+.btn {
  margin-left: 5px;
  margin-bottom: 0
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width:768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
  }

  .modal-sm {
    width: 300px
  }
}

@media (min-width:992px) {
  .modal-lg {
    width: 900px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 12px;
  opacity: 0;
  filter: alpha(opacity=0)
}

.tooltip.in {
  opacity: 0.9;
  filter: alpha(opacity=90)
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
}

.popover.top {
  margin-top: -10px
}

.popover.right {
  margin-left: 10px
}

.popover.bottom {
  margin-top: 10px
}

.popover.left {
  margin-left: -10px
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: -1 -1 0 0
}

.popover-content {
  padding: 9px 14px
}

.popover>.arrow,
.popover>.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.popover>.arrow {
  border-width: 11px
}

.popover>.arrow:after {
  border-width: 10px;
  content: ""
}

.popover.top>.arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px
}

.popover.top>.arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff
}

.popover.right>.arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999999;
  border-right-color: rgba(0, 0, 0, 0.25)
}

.popover.right>.arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff
}

.popover.bottom>.arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px
}

.popover.bottom>.arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff
}

.popover.left>.arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999999;
  border-left-color: rgba(0, 0, 0, 0.25)
}

.popover.left>.arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px
}

.carousel {
  position: relative
}

.carousel-inner {
  position: relative;
  overflow: hidden;
  width: 100%
}

.carousel-inner>.item {
  display: none;
  position: relative;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
  display: block;
  max-width: 100%;
  height: auto;
  line-height: 1
}

@media (-webkit-transform-3d),
(transform-3d) {
  .carousel-inner>.item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -moz-transition: -moz-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -moz-perspective: 1000px;
    perspective: 1000px
  }

  .carousel-inner>.item.active.right,
  .carousel-inner>.item.next {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    left: 0
  }

  .carousel-inner>.item.active.left,
  .carousel-inner>.item.prev {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    left: 0
  }

  .carousel-inner>.item.active,
  .carousel-inner>.item.next.left,
  .carousel-inner>.item.prev.right {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0
  }
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
  display: block
}

.carousel-inner>.active {
  left: 0
}

.carousel-inner>.next,
.carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%
}

.carousel-inner>.next {
  left: 100%
}

.carousel-inner>.prev {
  left: -100%
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
  left: 0
}

.carousel-inner>.active.left {
  left: -100%
}

.carousel-inner>.active.right {
  left: 100%
}

.carousel-control {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 15%;
  opacity: 0.5;
  filter: alpha(opacity=50);
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6)
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.0001) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1)
}

.carousel-control.right {
  left: auto;
  right: 0;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1)
}

.carousel-control:focus,
.carousel-control:hover {
  outline: 0;
  color: #fff;
  text-decoration: none;
  opacity: 0.9;
  filter: alpha(opacity=90)
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 5;
  display: inline-block
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  line-height: 1;
  font-family: serif
}

.carousel-control .icon-prev:before {
  content: '\2039'
}

.carousel-control .icon-next:before {
  content: '\203a'
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  margin-left: -30%;
  padding-left: 0;
  list-style: none;
  text-align: center
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  border: 1px solid #fff;
  border-radius: 10px;
  cursor: pointer;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0)
}

.carousel-indicators .active {
  margin: 0;
  width: 12px;
  height: 12px;
  background-color: #fff
}

.carousel-caption {
  position: absolute;
  left: 15%;
  right: 15%;
  bottom: 20px;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6)
}

.carousel-caption .btn {
  text-shadow: none
}

@media screen and (min-width:768px) {

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -15px;
    font-size: 30px
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -15px
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -15px
  }

  .carousel-caption {
    left: 20%;
    right: 20%;
    padding-bottom: 30px
  }

  .carousel-indicators {
    bottom: 20px
  }
}

.clearfix:after,
.clearfix:before {
  content: " ";
  display: table
}

.clearfix:after {
  clear: both
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.pull-right {
  float: right !important
}

.pull-left {
  float: left !important
}

.hide {
  display: none !important
}

.show {
  display: block !important
}

.invisible {
  visibility: hidden
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.hidden {
  display: none !important
}

.affix {
  position: fixed
}

@-ms-viewport {
  width: device-width
}

.visible-xs {
  display: none !important
}

.visible-sm {
  display: none !important
}

.visible-md {
  display: none !important
}

.visible-lg {
  display: none !important
}

.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important
}

@media (max-width:767px) {
  .visible-xs {
    display: block !important
  }

  table.visible-xs {
    display: table !important
  }

  tr.visible-xs {
    display: table-row !important
  }

  td.visible-xs,
  th.visible-xs {
    display: table-cell !important
  }
}

@media (max-width:767px) {
  .visible-xs-block {
    display: block !important
  }
}

@media (max-width:767px) {
  .visible-xs-inline {
    display: inline !important
  }
}

@media (max-width:767px) {
  .visible-xs-inline-block {
    display: inline-block !important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .visible-sm {
    display: block !important
  }

  table.visible-sm {
    display: table !important
  }

  tr.visible-sm {
    display: table-row !important
  }

  td.visible-sm,
  th.visible-sm {
    display: table-cell !important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .visible-sm-block {
    display: block !important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .visible-sm-inline {
    display: inline !important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .visible-sm-inline-block {
    display: inline-block !important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .visible-md {
    display: block !important
  }

  table.visible-md {
    display: table !important
  }

  tr.visible-md {
    display: table-row !important
  }

  td.visible-md,
  th.visible-md {
    display: table-cell !important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .visible-md-block {
    display: block !important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .visible-md-inline {
    display: inline !important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .visible-md-inline-block {
    display: inline-block !important
  }
}

@media (min-width:1200px) {
  .visible-lg {
    display: block !important
  }

  table.visible-lg {
    display: table !important
  }

  tr.visible-lg {
    display: table-row !important
  }

  td.visible-lg,
  th.visible-lg {
    display: table-cell !important
  }
}

@media (min-width:1200px) {
  .visible-lg-block {
    display: block !important
  }
}

@media (min-width:1200px) {
  .visible-lg-inline {
    display: inline !important
  }
}

@media (min-width:1200px) {
  .visible-lg-inline-block {
    display: inline-block !important
  }
}

@media (max-width:767px) {
  .hidden-xs {
    display: none !important
  }
}

@media (min-width:768px) and (max-width:991px) {
  .hidden-sm {
    display: none !important
  }
}

@media (min-width:992px) and (max-width:1199px) {
  .hidden-md {
    display: none !important
  }
}

@media (min-width:1200px) {
  .hidden-lg {
    display: none !important
  }
}

.visible-print {
  display: none !important
}

@media print {
  .visible-print {
    display: block !important
  }

  table.visible-print {
    display: table !important
  }

  tr.visible-print {
    display: table-row !important
  }

  td.visible-print,
  th.visible-print {
    display: table-cell !important
  }
}

.visible-print-block {
  display: none !important
}

@media print {
  .visible-print-block {
    display: block !important
  }
}

.visible-print-inline {
  display: none !important
}

@media print {
  .visible-print-inline {
    display: inline !important
  }
}

.visible-print-inline-block {
  display: none !important
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important
  }
}

@media print {
  .hidden-print {
    display: none !important
  }
}

.stick {
  position: fixed;
  top: 50px;
  width: 335px;
  z-index: 10000
}

@media (max-width:1200px) {
  .stick {
    width: 300px
  }
}

@media (max-width:992px) {
  .stick {
    position: relative;
    z-index: 0;
    top: 0px;
    width: 100%
  }
}

@media (max-width:768px) {
  .stick {
    position: relative;
    z-index: 0;
    top: 0px;
    width: 90%
  }
}

.btn {
  font-size: 15px;
  position: relative;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.btn.go {
  position: relative;
  text-decoration: none;
}

.btn-default {
  border-width: 2px
}

.btn-default:hover {
  color: #ffffff;
  background-color: #1e97d4;
  border-width: 2px;
  border-color: #1e97d4
}

.btn-primary {
  border-width: 2px
}

.btn-primary:hover {
  background-color: #1d5aa6
}

.front header {
  margin-bottom: 0
}

#navbar {
  background-color: #f5f5f5;
  border-color: #ffffff;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 0;
  box-shadow: 0 6px 6px -4px rgba(0, 0, 0, 0.05) inset
}

body #admin-menu {
  margin: 0;
  padding: 0;
  position: fixed;
  z-index: 1600
}

body #admin-menu .dropdown li {
  line-height: normal
}

body #navbar .nav>li:first-child>a {
  padding-right: 15px;
  margin-right: 15px;
  border-right: #cccccc solid 1px
}

body #navbar .nav>li:first-child>a:before {
  content: '\e60c';
  color: #aaaaaa;
  font-family: 'Autodesk-Icon-Fonts';
  padding-right: 7px;
  display: inline-block
}

body #navbar .nav>li>a {
  color: #1b96d4;
  padding: 0;
  margin: 15px 0
}

body #navbar .nav>li>a:hover {
  background: transparent;
  text-decoration: underline
}

body #navbar .nav>li.open>a {
  background: transparent
}

a.logo {
  font-size: 22px;
  color: #666666;
  text-decoration: none;
  margin-top: 8px
}

a.logo h1,
a.logo h2 {
  color: #666666;
  font-size: 22px;
  display: inline;
  margin: 0;
  padding: 0
}

a.logo img {
  margin-right: 5px
}

@media (max-width:768px) {
  a.logo span {
    display: none
  }
}

.page-header {
  margin: 30px 0 20px
}

@media (max-width:768px) {
  .page-header {
    margin: 10px 0 20px
  }
}

.page-header h1 {
  margin-top: 0;
  font-size: 32px
}

@media (max-width:768px) {
  .page-header h1 {
    font-size: 28px
  }
}

.page-header div[class^=col-] {
  padding: 0
}

.page-header div[class^=col-] h1 {
  margin-top: 10px;
  text-transform: capitalize
}

.page-header div[class^=col-] .btn {
  margin-bottom: 10px
}

.page-header div[class^=col-] button.btn {
  padding: 7px 20px 6px
}

@media (max-width:992px) {
  .page-header div[class^=col-] button.btn {
    padding: 7px 5px 6px
  }
}

@media (max-width:992px) {
  .page-header .pull-right {
    float: none !important
  }
}

.page-header .navbar-form {
  padding: 0
}

.page-header .navbar-form .btn {
  background: #888888;
  border: none;
  padding: 7px 20px 6px;
  color: #fff;
  font-weight: normal
}

.page-header .navbar-form .btn:hover {
  background: #6f6f6f
}

@media (max-width:992px) {
  .page-header .navbar-form {
    margin-top: 10px;
    margin-left: 0;
    margin-right: 0;
    float: none
  }

  .page-header .navbar-form .form-group {
    display: block
  }

  .page-header .navbar-form .input-group {
    display: block
  }

  .page-header .navbar-form input[type=text] {
    display: block;
    float: left;
    width: 75%
  }

  .page-header .navbar-form .input-group-btn {
    display: block;
    float: left;
    width: 25%
  }

  .page-header .navbar-form .input-group-btn button {
    width: 100%
  }
}

.front #navigation {
  min-height: 50px;
  max-height: 50px;
  background: #888888 !important;
  border-bottom: none !important
}

@media (max-width:768px) {
  .front #navigation {
    min-height: inherit;
    max-height: inherit
  }
}

.front #navigation ul li ul {
  display: none !important;
  z-index: -999
}

#navigation {
  min-height: 101px;
  max-height: 101px;
  background: #888888;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzg4ODg4OCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzg4ODg4OCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzg4ODg4OCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2ZmZmRmZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZkZmQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(top, #888888 0%, #888888 50%, #888888 50%, #fffdfd 50%, #fffdfd 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #888888), color-stop(50%, #888888), color-stop(50%, #888888), color-stop(50%, #fffdfd), color-stop(100%, #fffdfd));
  background: -webkit-linear-gradient(top, #888888 0%, #888888 50%, #888888 50%, #fffdfd 50%, #fffdfd 100%);
  background: -o-linear-gradient(top, #888888 0%, #888888 50%, #888888 50%, #fffdfd 50%, #fffdfd 100%);
  background: -ms-linear-gradient(top, #888888 0%, #888888 50%, #888888 50%, #fffdfd 50%, #fffdfd 100%);
  background: linear-gradient(to bottom, #888888 0%, #888888 50%, #888888 50%, #fffdfd 50%, #fffdfd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#888888', endColorstr='#fffdfd', GradientType=0);
  border-bottom: 1px solid #eeeeee
}

#navigation.no-drop {
  min-height: 50px;
  max-height: 50px;
  background: #888888 !important;
  border-bottom: none !important
}

@media (max-width:768px) {
  #navigation.no-drop {
    min-height: inherit;
    max-height: inherit;
    overflow: hidden
  }
}

@media (max-width:768px) {
  #navigation {
    min-height: inherit;
    max-height: inherit;
    overflow: hidden
  }
}

#navigation .container>ul {
  position: relative;
  box-shadow: none
}

#navigation .container>ul>li {
  position: static
}

#navigation .container>ul>li>a {
  color: #ffffff;
  box-shadow: none;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

#navigation .container>ul>li>a.active,
#navigation .container>ul>li>a.active-trail {
  background: #1b96d4
}

#navigation .container>ul>li>a:focus:not(.active-trail),
#navigation .container>ul>li>a:hover:not(.active-trail) {
  background: #6f6f6f
}

#navigation .container>ul>li ul {
  background: transparent;
  list-style: none;
  display: block !important;
  width: 100%;
  padding: 0 0;
  margin: 0 0;
  left: 0;
  z-index: 1;
  border: none;
  box-shadow: none
}

#navigation .container>ul>li ul li {
  display: inline-block
}

#navigation .container>ul>li ul li a {
  color: #888888;
  padding: 18px 0;
  margin: 0 32px 0 0;
  line-height: 15px;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

#navigation .container>ul>li ul li a.active-trail {
  color: #1b96d4;
  padding: 18px 0 16px;
  border-bottom: #1b96d4 solid 2px
}

#navigation .container>ul>li ul li a:focus,
#navigation .container>ul>li ul li a:hover {
  background: transparent;
  color: #1b96d4
}

@media (max-width:768px) {
  #navigation .container {
    padding: 0
  }

  #navigation .container>ul {
    background: #888888;
    margin: 0 0
  }

  #navigation .container>ul>li {
    border-bottom: solid 1px #7b7b7b
  }

  #navigation .container>ul>li.dropdown,
  #navigation .container>ul>li:last-child {
    border-bottom: none
  }

  #navigation .container>ul>li ul {
    background: #f5f5f5;
    position: relative;
    float: none;
    left: auto;
    top: auto
  }

  #navigation .container>ul>li ul li {
    display: block
  }

  #navigation .container>ul>li ul li a {
    padding: 10px 15px 10px 25px;
    margin-right: 0;
    border-bottom: 1px solid #cccccc
  }

  #navigation .container>ul>li ul li a.active-trail {
    padding: 10px 15px 10px 25px;
    border-bottom: 1px solid #cccccc
  }
}

div.error {
  background: transparent
}

div.error input[type=text] {
  border: #ebccd1 solid 1px;
  color: #a94442
}

#navigation .sf-menu.sf-style-white>li>a:focus {
  background: #6f6f6f !important
}

#navigation .sf-menu.sf-style-white>li>ul>li a:focus {
  background: transparent !important;
  color: #1b96d4;
  padding: 18px 0 16px 0 !important;
  border-bottom: #1b96d4 solid 2px
}

#navigation .sf-menu.sf-style-white.sf-navbar {
  background: #888888;
  margin-bottom: 0
}

#navigation .sf-menu.sf-style-white.sf-navbar>li {
  background: #888888
}

#navigation .sf-menu.sf-style-white.sf-navbar>li:hover>a {
  background: #6f6f6f
}

#navigation .sf-menu.sf-style-white.sf-navbar>li.sf-breadcrumb:hover>a {
  background: #1b96d4
}

#navigation .sf-menu.sf-style-white.sf-navbar>li.sf-breadcrumb>a {
  background: #1b96d4
}

#navigation .sf-menu.sf-style-white.sf-navbar>li.sf-breadcrumb.sfHover>a {
  background: #1b96d4
}

#navigation .sf-menu.sf-style-white.sf-navbar>li.sf-breadcrumb.sfHover:hover>a {
  background: #1b96d4
}

#navigation .sf-menu.sf-style-white.sf-navbar>li>a {
  color: #ffffff;
  padding: 18px 16px
}

#navigation .sf-menu.sf-style-white.sf-navbar>li a {
  text-decoration: none
}

#navigation .sf-menu.sf-style-white.sf-navbar>li>a {
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

#navigation .sf-menu.sf-style-white.sf-navbar>li ul {
  background-color: #fffdfd;
  top: 50px
}

#navigation .sf-menu.sf-style-white.sf-navbar>li ul li {
  background-color: #fffdfd
}

#navigation .sf-menu.sf-style-white.sf-navbar>li ul li a {
  padding: 18px 0;
  margin-right: 32px
}

#navigation .sf-menu.sf-style-white.sf-navbar>li ul li a.active,
#navigation .sf-menu.sf-style-white.sf-navbar>li ul li a:hover {
  color: #1b96d4;
  background: transparent;
  padding: 18px 0 16px 0;
  border-bottom: #1b96d4 solid 2px
}

.content-actions .tabs--primary {
  margin-bottom: 20px
}

.content-actions .tabs--primary>li+li {
  margin-left: 10px
}

.content-actions .tabs--primary>li>a {
  background: #f5f5f5;
  color: #333333;
  padding: 8px 15px
}

.content-actions .tabs--primary>li>a:hover {
  color: #1e97d4
}

.content-actions .tabs--primary>li>a.active {
  background: #1b96d4;
  color: #ffffff
}

.content-actions hr {
  display: none
}

.front .page-header {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: auto;
  border-bottom: 0
}

.front .page-header-content>section {
  width: 100%
}

.front .page-header-content div section:after,
.front .page-header-content div section:before {
  content: none
}

.front .page-header .field-items .field-item .header {
  display: table;
  width: 100%;
  min-height: 100%;
  height: 460px;
  background-image: url("../images/autodesk_sample_banner.jpg");
  background-size: cover;
  background-position: center
}

.front .page-header .field-items .content {
  background-image: url("../images/patterns/header-polygon-pattern.png");
  background-position: left bottom;
  background-repeat: no-repeat;
  display: table-cell;
  vertical-align: middle
}

@media (max-width:768px) {
  .front .page-header .field-items .content {
    text-align: center;
    padding: 0 20px
  }
}

.front .page-header .field-items .content h1 {
  font-size: 40px;
  line-height: 52px
}

@media (max-width:768px) {
  .front .page-header .field-items .content h1 {
    font-size: 30px;
    line-height: 42px
  }
}

.front .page-header .field-items .content p {
  color: #666666;
  color: #666666;
  font-size: 17px;
  margin-bottom: 15px
}

.front .page-header .field-items .banner {
  padding: 10px 10.6%
}

#block-views-api-reference-docs-block-1 {
  background-color: #1b94d1;
  background-image: url("../images/patterns/products-left-pattern.png"), url("../images/patterns/products-right-pattern.png");
  background-position: left top, right bottom;
  background-repeat: no-repeat;
  padding: 75px 0
}

#block-views-api-reference-docs-block-1 h3 {
  color: #ffffff;
  font-size: 32px;
  text-align: center
}

#block-views-api-reference-docs-block-1 .row {
  padding: 45px 0 20px 0;
  text-align: center
}

@media (min-width:1200px) {
  #block-views-api-reference-docs-block-1 .row>div {
    display: inline-block;
    float: none;
    text-align: left;
    margin-right: -4px
  }
}

#block-views-api-reference-docs-block-1 .row a {
  color: #999999;
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
  display: block;
  text-align: center;
  background-color: #ffffff;
  padding: 30px;
  border: 2px solid #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

#block-views-api-reference-docs-block-1 .row a:hover {
  border: 2px solid #9cbbda
}

#block-views-api-reference-docs-block-1 .row a>div {
  margin-top: 20px
}

#block-views-dev-portal-blog-content-block-2,
#block-views-devconnect-blog-block-2 {
  background-image: url("../images/patterns/blogforum-left-pattern.png"), url("../images/patterns/blogforum-right-pattern.png");
  background-position: left bottom, right top;
  background-repeat: no-repeat;
  padding: 75px 0
}

#block-views-dev-portal-blog-content-block-2 h3,
#block-views-devconnect-blog-block-2 h3 {
  font-size: 32px;
  text-align: center;
  margin-top: 0
}

@media (max-width:768px) {

  #block-views-dev-portal-blog-content-block-2 h3,
  #block-views-devconnect-blog-block-2 h3 {
    font-size: 26px
  }
}

#block-views-dev-portal-blog-content-block-2 .row,
#block-views-devconnect-blog-block-2 .row {
  padding: 45px 0 20px 0
}

#block-views-dev-portal-blog-content-block-2 .row>div>h3,
#block-views-devconnect-blog-block-2 .row>div>h3 {
  text-align: left
}

#block-views-dev-portal-blog-content-block-2 .row>div>h3 a:first-child,
#block-views-devconnect-blog-block-2 .row>div>h3 a:first-child {
  font-size: 22px;
  text-decoration: none
}

#block-views-dev-portal-blog-content-block-2 .row>div p,
#block-views-devconnect-blog-block-2 .row>div p {
  margin: 10px 0 25px 0
}

@media (max-width:1200px) {

  #block-views-dev-portal-blog-content-block-2 .row>div,
  #block-views-devconnect-blog-block-2 .row>div {
    width: 50%;
    float: left
  }
}

@media (max-width:992px) {

  #block-views-dev-portal-blog-content-block-2 .row>div,
  #block-views-devconnect-blog-block-2 .row>div {
    width: 50%;
    float: left
  }
}

@media (max-width:768px) {

  #block-views-dev-portal-blog-content-block-2 .row>div,
  #block-views-devconnect-blog-block-2 .row>div {
    width: 100%;
    margin-bottom: 50px;
    text-align: center
  }

  #block-views-dev-portal-blog-content-block-2 .row>div h3,
  #block-views-devconnect-blog-block-2 .row>div h3 {
    text-align: center
  }

  #block-views-dev-portal-blog-content-block-2 .row>div:last-child,
  #block-views-devconnect-blog-block-2 .row>div:last-child {
    margin-bottom: 0
  }
}

#block-bean-building-an-app-home {
  background-color: #1d5aa6;
  background-image: url("../images/patterns/steps-left-pattern.png");
  background-position: left top;
  background-repeat: no-repeat;
  padding: 75px 0
}

#block-bean-building-an-app-home h3 {
  color: #ffffff;
  font-size: 32px;
  text-align: center;
  margin-top: 0
}

@media (max-width:768px) {
  #block-bean-building-an-app-home h3 {
    font-size: 26px
  }
}

#block-bean-building-an-app-home .row {
  padding: 45px 0 20px 0
}

#block-bean-building-an-app-home .col-md-4 {
  text-align: center
}

@media (max-width:992px) {
  #block-bean-building-an-app-home .col-md-4 {
    margin-bottom: 50px
  }
}

@media (max-width:768px) {
  #block-bean-building-an-app-home .col-md-4 {
    margin-bottom: 50px
  }

  #block-bean-building-an-app-home .col-md-4:last-child {
    margin-bottom: 30px
  }
}

#block-bean-building-an-app-home .col-md-4>div:first-child {
  padding: 0 0 10px 0;
  display: block
}

#block-bean-building-an-app-home .col-md-4>div:nth-child(3) {
  text-align: left;
  padding: 0 40px
}

#block-bean-building-an-app-home .col-md-4>div:nth-child(3)>div {
  display: inline-block;
  float: left;
}

#block-bean-building-an-app-home .col-md-4>div:nth-child(3)>div:first-child {
  color: #89cce4;
  font-size: 48px;
  line-height: 48px;
  padding-right: 15px;
  width: 15%;
  text-align: right
}

#block-bean-building-an-app-home .col-md-4>div:nth-child(3)>div:last-child {
  color: #ffffff;
  padding: 1px 0 0 0;
  width: 85%
}

#block-bean-building-an-app-home .col-md-4 h5 {
  color: #ffffff;
  font-size: 24px
}

#block-bean-building-an-app-home .btn {
  color: #1d5aa6;
  font-size: 18px;
  font-weight: lighter;
  padding: 17px 43px 16px 50px
}

#block-bean-building-an-app-home .btn:before {
  content: '\e613';
  font-family: 'Autodesk-Icon-Fonts';
  font-size: 34px;
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 9px
}

#block-bean-building-an-app-home .btn:after {
  font-size: 19px;
  top: 17px
}

#block-bean-building-an-app-home .btn:hover {
  color: #ffffff
}

.faq .accordion .panel {
  box-shadow: none
}

.faq .accordion .panel-heading {
  padding: 0;
  background-color: transparent;
  border: none
}

.faq .accordion .panel-heading a {
  font-size: 24px;
  color: #1b96d4;
  text-decoration: none;
  display: inline-block;
  padding: 20px 28px 20px;
  width: 100%;
  position: relative
}

.faq .accordion .panel-heading a:before {
  content: '\e618';
  font-family: 'Autodesk-Icon-Fonts';
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 23px;
  left: 1px
}

.faq .accordion .panel-heading a.collapsed {
  text-decoration: none
}

.faq .accordion .panel-heading a.collapsed:before {
  content: '\e617';
  font-family: 'Autodesk-Icon-Fonts'
}

.faq .accordion .panel-default {
  border: none;
  border-bottom: #eeeeee 1px solid
}

.faq .accordion .panel-default .panel-body {
  border: none;
  padding: 0 0 25px 28px
}

.faq .accordion .panel-default:last-child {
  border-bottom: none
}

.faq .accordion .panel-default:last-child .panel-body {
  border-bottom: #eeeeee 1px solid
}

.faq .faq-header:before {
  content: "\276F";
  font-size: 20px;
  margin-right: 10px
}

.faq .expanded .faq-header:before {
  content: "\276F";
  display: inline-block;
  transform: rotate(90deg);
  font-size: 20px;
  margin-right: 10px
}

.faq .faq-qa>div .faq-question {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-left: 25px
}

.faq .faq-qa>div .faq-question span {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit
}

.faq .faq-qa>div .faq-question span a {
  font-size: 24px;
  color: #1b96d4;
  text-decoration: none;
  display: inline-block;
  padding: 20px 28px 20px;
  width: 100%;
  position: relative
}

.faq .faq-qa>div .faq-question span a:before {
  content: '\e617';
  font-family: 'Autodesk-Icon-Fonts';
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 23px;
  left: 1px
}

.faq .faq-qa>div .faq-question span a.expanded {
  text-decoration: none
}

.faq .faq-qa>div .faq-question span a.expanded:before {
  content: '\e618';
  font-family: 'Autodesk-Icon-Fonts'
}

.faq .faq-qa>div .faq-answer {
  padding-left: 55px;
  display: none
}

.my-apps-accordion {
  margin-bottom: 25px
}

.my-apps-accordion:last-child .panel-default {
  border: none;
  border-bottom: none
}

.my-apps-accordion .panel {
  box-shadow: none
}

.my-apps-accordion .panel-heading {
  padding: 0;
  background-color: transparent;
  border: none
}

.my-apps-accordion .panel-heading a {
  font-size: 24px;
  color: #1b96d4;
  text-decoration: none;
  display: inline-block;
  padding: 0 0 20px 28px;
  width: 100%;
  position: relative
}

.my-apps-accordion .panel-heading a:before {
  content: '\e618';
  font-family: 'Autodesk-Icon-Fonts';
  font-size: 18px;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 1px
}

.my-apps-accordion .panel-heading a.collapsed {
  text-decoration: none;
  border: none
}

.my-apps-accordion .panel-heading a.collapsed:before {
  content: '\e617';
  font-family: 'Autodesk-Icon-Fonts'
}

.my-apps-accordion .panel-heading a>div {
  padding: 0
}

@media (max-width:768px) {
  .my-apps-accordion .panel-heading a>div {
    text-align: left
  }
}

.my-apps-accordion .panel-heading a>div:last-child {
  color: #666666;
  font-size: 15px;
  margin-top: 20px
}

.my-apps-accordion .panel-heading a>div.text-right {
  color: #666666;
  font-size: 18px;
  padding-top: 8px
}

@media (max-width:768px) {
  .my-apps-accordion .panel-heading a>div.text-right {
    text-align: left !important
  }
}

.my-apps-accordion .panel-body {
  border-top: none !important;
  border-bottom: #eeeeee 1px solid;
  padding-top: 0;
  padding: 0 0 20px 28px
}

.my-apps-accordion .panel-body .app-data {
  margin-bottom: 15px
}

.my-apps-accordion .panel-body .app-data>div {
  font-size: 15px
}

.my-apps-accordion .panel-body .app-data>div:first-child {
  font-weight: bold;
  font-size: 17px
}

.my-apps-accordion .panel-body .actions-links a {
  font-size: 15px;
  display: inline-block;
  margin-right: 10px
}

.my-apps-accordion .panel-default {
  border: none;
  border-bottom: #eeeeee 1px solid
}

.add-app-button,
.company-switcher {
  display: inline-block
}

.add-app-button .button,
.add-app-button .dropdown,
.company-switcher .button,
.company-switcher .dropdown {
  display: inline-block
}

.add-app-button .caret,
.company-switcher .caret {
  margin-left: 5px
}

.add-app-button .dropdown-menu,
.company-switcher .dropdown-menu {
  top: 80%
}

#devconnect-developer-apps-edit-form h3 {
  font-size: 24px;
  text-transform: none;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee
}

#devconnect-developer-apps-edit-form .form-item {
  margin-top: 20px
}

@media (max-width:768px) {
  #devconnect-developer-apps-edit-form .form-item input[type=text] {
    width: 100%
  }
}

#devconnect-developer-apps-edit-form label {
  font-weight: normal
}

#devconnect-developer-apps-edit-form .btn-default {
  color: #ffffff;
  background: #a4a4a4;
  border-color: #a4a4a4
}

#devconnect-developer-apps-edit-form .btn-default:hover {
  background: #8b8b8b;
  border-color: #8b8b8b
}

#api_product {
  margin: 0 -10px
}

#api_product:after {
  content: ' ';
  clear: both;
  float: none;
  display: table;
  width: 100%
}

#api_product>div {
  display: block;
  float: left;
  width: 25%;
  margin: 0 0 20px 0 !important;
  padding: 0 10px
}

@media screen and (max-width:992px) {
  #api_product>div {
    width: 33.3%
  }
}

@media screen and (max-width:768px) {
  #api_product {
    margin: 0 -10px
  }

  #api_product>div {
    width: 50%;
    margin-bottom: 20px !important;
    padding: 0 10px
  }
}

@media screen and (max-width:440px) {
  #api_product>div {
    width: 100%
  }
}

#api_product>div input {
  display: none
}

#api_product>div label {
  display: block;
  // background-image: url("../images/icon_place_order.png");
  background-position: center 40px;
  background-repeat: no-repeat;
  color: #999999;
  font-weight: bold;
  text-decoration: none;
  font-size: 17px;
  text-align: center;
  background-color: #ffffff;
  padding: 150px 30px 30px 30px;
  height: 100%;
  border: 2px solid #ffffff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -moz-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

#api_product>div label:hover {
  border: 2px solid #9cbbda
}

#api_product>div label.checked {
  border: #2962ab solid 2px
}

#api_product>div label .apiproduct-description {
  display: none
}

#api_product>div label[for=edit-api-product-prod-ddws-get-invoices] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-07.png")
}

#api_product>div label[for=edit-api-product-prod-ddws-get-license] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-08.png")
}

#api_product>div label[for=edit-api-product-prod-ddws-get-price] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-06.png")
}

#api_product>div label[for=edit-api-product-prod-ddws-get-orderdetailsandlist] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-03.png")
}

#api_product>div label[for=edit-api-product-prod-ddws-place-order] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-01.png")
}

#api_product>div label[for=edit-api-product-prod-order-status] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-02.png")
}

#api_product>div label[for=edit-api-product-prod-revoke-order] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-05.png")
}

#api_product>div label[for=edit-api-product-prod-recurring-po] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-04.png")
}

#api_product>div label[for=edit-api-product-prod-getpartnerdesignation] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-09.png")
}

#api_product>div label[for=edit-api-product-prod-getopportunity] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-10.png")
}

#api_product>div label[for=edit-api-product-prod-get-part-number] {
  background-image: url("../images/icons/product-icons-png/adsk-product-icons-11.png")
}

iframe {
  border-top: #cdcdcd solid 1px;
  border-left-width: 0
}

.view-smartdocs-models .model-info {
  border-bottom: 1px solid #eeeeee;
  padding: 10px 0 20px 0
}

.view-smartdocs-models .model-info:last-child {
  border-bottom: none
}

.view-smartdocs-models .model-info .views-field-description {
  margin-top: 10px
}

.view-smartdocs-models .model-info a {
  font-family: Arial;
  font-weight: normal;
  font-size: 24px;
  color: #1b96d4;
  text-decoration: none
}

.model-description {
  font-size: 15px;
  color: #666666
}

.view-display-id-modelindex h3.method-tag-header,
.view-display-id-modelindex h3.method-tag-header {
  font-size: 28px;
  margin-top: 35px;
  margin-bottom: 0
}

.view-display-id-modelindex h3.method-tag-header:first-child,
.view-display-id-modelindex h3.method-tag-header:first-child {
  margin-top: 25px
}

.view-display-id-modelindex .views-row>div {
  height: 100%
}

.view-display-id-modelindex .views-row>div>div {
  height: 100%
}

.view-display-id-modelindex .views-row>div>div>div {
  height: 100%
}

.view-display-id-modelindex .method_details.clearfix {
  border-bottom: none
}

.view-display-id-modelindex .method_details.clearfix .description-header,
.view-display-id-modelindex .method_details.clearfix .title-header {
  color: #666666;
  font-size: 15px;
  text-transform: none;
  padding: 25px 0 20px 0;
  height: auto !important
}

@media (max-width:992px) {
  .view-display-id-modelindex .method_details.clearfix .title-header {
    width: 30%
  }

  .view-display-id-modelindex .method_details.clearfix .description-header {
    width: 70%
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix {
  padding: 0;
  width: 100%;
  display: table;
  border-bottom: solid 1px #ececec
}

@media (max-width:992px) {
  .view-display-id-modelindex .field-content>.method_details.clearfix {
    border: solid 1px #ececec
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix:hover>div>div>p.GET {
  background: #89cded
}

.view-display-id-modelindex .field-content>.method_details.clearfix:hover>div>div>p.POST {
  background: #c3dea0
}

.view-display-id-modelindex .field-content>.method_details.clearfix:hover>div>div>p.PUT {
  background: #ffeaa5
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div {
  float: none;
  vertical-align: middle;
  display: table-cell
}

@media (max-width:992px) {
  .view-display-id-modelindex .field-content>.method_details.clearfix>div {
    display: block;
    width: 100%;
    height: auto !important
  }

  .view-display-id-modelindex .field-content>.method_details.clearfix>div:last-child {
    padding: 20px
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child {
  width: 50%
}

@media (max-width:992px) {
  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child {
    display: block;
    width: 100%;
    border-bottom: solid 1px #ececec
  }

  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child:after,
  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child:before {
    content: " ";
    display: table;
    width: 100%;
    float: none;
    clear: both
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child {
  padding: 0;
  width: 20%;
  height: 100%;
  display: table
}

@media (max-width:768px) {
  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child {
    width: 30%
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div,
.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p {
  color: #333333;
  font-size: 18px;
  text-transform: capitalize;
  float: none;
  margin: 0;
  height: 100%;
  display: table-cell;
  padding: 44px 20px;
  vertical-align: middle;
  border-radius: 0;
  position: relative;
  -webkit-transition: all .2s ease-in-out;
  -moz-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

@media (max-width:768px) {

  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div,
  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p {
    width: 100%;
    min-width: 100%;
    max-width: 100%
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div.GET,
.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p.GET {
  background: #b5e0f4
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div.POST,
.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p.POST {
  background: #dbebc6
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div.PUT,
.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p.PUT {
  background: #fff6d8
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>div.lock:before,
.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>p.lock:before {
  content: '\e610';
  color: #999999;
  font-family: 'Autodesk-Icon-Fonts';
  font-size: 14px;
  position: absolute;
  top: 9px;
  right: 7px
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:first-child>span {
  display: none
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:last-child {
  padding: 25px 15px;
  width: 80%;
  max-width: 100%
}

@media (max-width:768px) {
  .view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:last-child {
    width: 70%
  }
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:last-child a {
  font-size: 18px
}

.view-display-id-modelindex .field-content>.method_details.clearfix>div:first-child>div:last-child p {
  line-height: 16px
}

.node-dev-portal-reference-docs section {
  margin-bottom: 40px
}

.node-dev-portal-reference-docs h2 {
  font-size: 28px;
  margin-bottom: 30px
}

.node-dev-portal-reference-docs h3 {
  font-size: 24px;
  margin-bottom: 20px
}

.node-dev-portal-reference-docs h4 {
  font-size: 20px;
  margin-bottom: 10px
}

.node-dev-portal-reference-docs .table-responsive {
  margin-top: 20px
}

.node-dev-portal-reference-docs .table-responsive table {
  word-break: break-all
}

.node-dev-portal-reference-docs pre {
  margin-bottom: 30px
}

.block-devconnect-content-creation-menu {
  margin-bottom: 10px
}

.block-devconnect-content-creation-menu hr {
  display: none
}

.block-devconnect-content-creation-menu .navbar-default {
  background-color: transparent;
  border: none;
  padding: 0 15px
}

.block-devconnect-content-creation-menu .navbar-default .navbar-header {
  float: none;
  margin-bottom: 5px
}

.block-devconnect-content-creation-menu .navbar-default .navbar-header a {
  float: none;
  padding: 0;
  font-size: 20px
}

.block-devconnect-content-creation-menu .navbar-default .navbar-nav {
  float: none
}

.block-devconnect-content-creation-menu .navbar-default .navbar-nav .btn.navbar-btn {
  background-color: transparent;
  position: relative;
  border-radius: 0;
  margin: 0;
  padding: 0
}

.block-devconnect-content-creation-menu .navbar-default .navbar-nav .btn.navbar-btn span {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: all .0 ease
}

.block-devconnect-content-creation-menu .navbar-default .navbar-nav .btn.navbar-btn a {
  background: #f5f5f5;
  color: #333333 !important;
  text-decoration: none;
  font-size: 14px;
  font-weight: normal;
  padding: 8px 15px 8px 32px;
  display: inline-block;
  transition: none
}

.block-devconnect-content-creation-menu .navbar-default .navbar-nav .btn.navbar-btn a:hover {
  color: #1b96d4 !important
}

.node-article.node-teaser .node-body img,
.node-blog-audio.node-teaser .node-body img,
.node-blog-image.node-teaser .node-body img,
.node-blog-video.node-teaser .node-body img,
.view-id-devconnect_blog.view-display-id-page .node-body img {
  width: 100%
}

.node-article.node-teaser .node-body .node-date,
.node-blog-audio.node-teaser .node-body .node-date,
.node-blog-image.node-teaser .node-body .node-date,
.node-blog-video.node-teaser .node-body .node-date,
.view-id-devconnect_blog.view-display-id-page .node-body .node-date {
  background: #1b96d4;
  float: none;
  text-align: center;
  padding: 19px 20px 17px;
  margin: 0;
  border: none;
  border-radius: 0
}

.node-article.node-teaser .node-body .node-date .month,
.node-blog-audio.node-teaser .node-body .node-date .month,
.node-blog-image.node-teaser .node-body .node-date .month,
.node-blog-video.node-teaser .node-body .node-date .month,
.view-id-devconnect_blog.view-display-id-page .node-body .node-date .month {
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  display: inline-block
}

.node-article.node-teaser .node-body .node-date .day,
.node-blog-audio.node-teaser .node-body .node-date .day,
.node-blog-image.node-teaser .node-body .node-date .day,
.node-blog-video.node-teaser .node-body .node-date .day,
.view-id-devconnect_blog.view-display-id-page .node-body .node-date .day {
  color: #B8DEF1;
  font-size: 38px;
  line-height: 38px;
  display: inline-block
}

@media (max-width:992px) {

  .node-article.node-teaser .node-body .node-date,
  .node-blog-audio.node-teaser .node-body .node-date,
  .node-blog-image.node-teaser .node-body .node-date,
  .node-blog-video.node-teaser .node-body .node-date,
  .view-id-devconnect_blog.view-display-id-page .node-body .node-date {
    margin-bottom: 20px
  }

  .node-article.node-teaser .node-body .node-date div.day,
  .node-article.node-teaser .node-body .node-date div.month,
  .node-blog-audio.node-teaser .node-body .node-date div.day,
  .node-blog-audio.node-teaser .node-body .node-date div.month,
  .node-blog-image.node-teaser .node-body .node-date div.day,
  .node-blog-image.node-teaser .node-body .node-date div.month,
  .node-blog-video.node-teaser .node-body .node-date div.day,
  .node-blog-video.node-teaser .node-body .node-date div.month,
  .view-id-devconnect_blog.view-display-id-page .node-body .node-date div.day,
  .view-id-devconnect_blog.view-display-id-page .node-body .node-date div.month {
    font-size: 28px
  }
}

.node-article.node-teaser .node-body h2,
.node-blog-audio.node-teaser .node-body h2,
.node-blog-image.node-teaser .node-body h2,
.node-blog-video.node-teaser .node-body h2,
.view-id-devconnect_blog.view-display-id-page .node-body h2 {
  color: #333333;
  font-size: 28px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px
}

.node-article.node-teaser .node-body h2 a,
.node-blog-audio.node-teaser .node-body h2 a,
.node-blog-image.node-teaser .node-body h2 a,
.node-blog-video.node-teaser .node-body h2 a,
.view-id-devconnect_blog.view-display-id-page .node-body h2 a {
  color: #333333
}

.node-article.node-teaser .node-body .node-info,
.node-blog-audio.node-teaser .node-body .node-info,
.node-blog-image.node-teaser .node-body .node-info,
.node-blog-video.node-teaser .node-body .node-info,
.view-id-devconnect_blog.view-display-id-page .node-body .node-info {
  margin-bottom: 20px
}

.node-article.node-teaser .node-body .node-info>div,
.node-blog-audio.node-teaser .node-body .node-info>div,
.node-blog-image.node-teaser .node-body .node-info>div,
.node-blog-video.node-teaser .node-body .node-info>div,
.view-id-devconnect_blog.view-display-id-page .node-body .node-info>div {
  color: #666666;
  display: inline-block;
  padding-right: 10px;
  margin-right: 10px
}

.node-article.node-teaser .node-body .node-info>div:first-child,
.node-blog-audio.node-teaser .node-body .node-info>div:first-child,
.node-blog-image.node-teaser .node-body .node-info>div:first-child,
.node-blog-video.node-teaser .node-body .node-info>div:first-child,
.view-id-devconnect_blog.view-display-id-page .node-body .node-info>div:first-child {
  border-right: #666666 solid 1px
}

.node-article.node-teaser .node-body .field-name-body,
.node-article.node-teaser .node-body .file,
.node-blog-audio.node-teaser .node-body .field-name-body,
.node-blog-audio.node-teaser .node-body .file,
.node-blog-image.node-teaser .node-body .field-name-body,
.node-blog-image.node-teaser .node-body .file,
.node-blog-video.node-teaser .node-body .field-name-body,
.node-blog-video.node-teaser .node-body .file,
.view-id-devconnect_blog.view-display-id-page .node-body .field-name-body,
.view-id-devconnect_blog.view-display-id-page .node-body .file {
  margin-bottom: 20px
}

.node-article.node-teaser .node-body .field-name-body audio,
.node-article.node-teaser .node-body .field-name-body img,
.node-article.node-teaser .node-body .field-name-body video,
.node-article.node-teaser .node-body .file audio,
.node-article.node-teaser .node-body .file img,
.node-article.node-teaser .node-body .file video,
.node-blog-audio.node-teaser .node-body .field-name-body audio,
.node-blog-audio.node-teaser .node-body .field-name-body img,
.node-blog-audio.node-teaser .node-body .field-name-body video,
.node-blog-audio.node-teaser .node-body .file audio,
.node-blog-audio.node-teaser .node-body .file img,
.node-blog-audio.node-teaser .node-body .file video,
.node-blog-image.node-teaser .node-body .field-name-body audio,
.node-blog-image.node-teaser .node-body .field-name-body img,
.node-blog-image.node-teaser .node-body .field-name-body video,
.node-blog-image.node-teaser .node-body .file audio,
.node-blog-image.node-teaser .node-body .file img,
.node-blog-image.node-teaser .node-body .file video,
.node-blog-video.node-teaser .node-body .field-name-body audio,
.node-blog-video.node-teaser .node-body .field-name-body img,
.node-blog-video.node-teaser .node-body .field-name-body video,
.node-blog-video.node-teaser .node-body .file audio,
.node-blog-video.node-teaser .node-body .file img,
.node-blog-video.node-teaser .node-body .file video,
.view-id-devconnect_blog.view-display-id-page .node-body .field-name-body audio,
.view-id-devconnect_blog.view-display-id-page .node-body .field-name-body img,
.view-id-devconnect_blog.view-display-id-page .node-body .field-name-body video,
.view-id-devconnect_blog.view-display-id-page .node-body .file audio,
.view-id-devconnect_blog.view-display-id-page .node-body .file img,
.view-id-devconnect_blog.view-display-id-page .node-body .file video {
  width: 100%
}

.node audio,
.node video {
  width: 100%
}

#block-views-hot-topics-block h2 {
  color: #333333;
  font-size: 26px;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px
}

#block-views-hot-topics-block ul {
  padding: 0;
  margin: 0;
  list-style: none
}

#block-views-hot-topics-block ul li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eeeeee
}

#block-views-hot-topics-block ul li a {
  font-size: 16px
}

.node-type-article .node-article .list-inline {
  margin-left: 0;
  margin-bottom: 0
}

.node-type-article #comment-form .filter-wrapper {
  margin-top: 30px
}

.node-type-article #comment-form .filter-wrapper .form-type-select {
  margin-top: 0
}

@media (max-width:768px) {
  .node-type-article #comment-form .filter-wrapper .form-type-select select {
    width: 100%
  }
}

@media (max-width:768px) {
  .node-type-article #comment-form input[type=text].form-control {
    width: 100%
  }
}

.front .footer {
  margin-top: 0
}

.footer {
  color: #666666;
  background-color: #eeeeee;
  text-align: center
}

.footer .footer-links,
.footer .language-switcher-locale-url {
  display: inline-block;
  margin-bottom: 20px;
  padding-left: 0
}

.footer .footer-links li,
.footer .language-switcher-locale-url li {
  display: inline-block;
  margin: 0;
  padding: 5px 15px 5px 10px;
  border-right: #cccccc solid 1px
}

.footer .footer-links li:last-child,
.footer .language-switcher-locale-url li:last-child {
  border-right: none
}

.footer .footer-links li a,
.footer .language-switcher-locale-url li a {
  padding: 0
}

.footer .footer-links li a:hover,
.footer .language-switcher-locale-url li a:hover {
  text-decoration: underline
}

@media (max-width:768px) {

  .footer .footer-links li,
  .footer .language-switcher-locale-url li {
    display: block;
    border-right: none;
    padding: 5px 0
  }
}

.form-control,
.form-email {
  display: block;
  width: 620px;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #555;
  vertical-align: middle;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
}

@media (max-width:1200px) {

  .form-control,
  .form-email {
    width: 620px
  }
}

@media (max-width:992px) {

  .form-control,
  .form-email {
    width: 100%
  }
}

@media (max-width:768px) {

  .form-control,
  .form-email {
    width: 100%
  }
}

#webform-client-form-6 #edit-submitted-contact-product-service {
  border: 1px solid #cccccc;
  background: #ffffff;
  color: #333333;
  font-size: 15px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  transition: none
}

#webform-client-form-6 #edit-submitted-contact-product-service:hover {
  border: 1px solid #cccccc;
  background: #eeeeee;
  color: #333333;
  font-size: 15px
}

#webform-client-form-6 #edit-submitted-contact-product-service option {
  background: #fff
}

#webform-client-form-6 .error {
  border-color: red;
  color: #333;
  outline: none
}

.form-control:hover,
.form-email:hover {
  border: 1px solid #333
}

.form-control:focus,
.form-email:focus {
  border: 1px solid #1e97d4
}

label[for=edit-submitted-contact-email-address] {
  display: block;
  font-size: 15px;
  font-weight: normal
}

#node-6 .content label {
  font-weight: normal;
  font-size: 15px;
  line-height: 23px;
  color: #333
}

#webform-client-form-6 button {
  margin-top: 30px
}

#webform-client-form-6 .form-textarea-wrapper {
  width: 620px
}

@media (max-width:1200px) {
  #webform-client-form-6 .form-textarea-wrapper {
    width: 620px
  }
}

@media (max-width:992px) {
  #webform-client-form-6 .form-textarea-wrapper {
    width: 100%
  }
}

@media (max-width:768px) {
  #webform-client-form-6 .form-textarea-wrapper {
    width: 100%
  }
}

.form-item {
  margin-top: 30px
}

.left-nav .collapsing {
  -webkit-transition: none;
  transition: none
}

.left-nav #accordion .panel {
  box-shadow: none
}

.left-nav #accordion .panel-default {
  border-bottom-color: 0px;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px
}

.left-nav #accordion .panel-heading {
  background-color: #fff;
  font-family: Arial;
  font-size: 18px;
  line-height: 24px
}

.left-nav #accordion .panel-body {
  font-family: Arial;
  font-size: 15px;
  line-height: 23px
}

.left-nav #accordion .panel-default a:link {
  color: #333333
}

.left-nav #accordion .panel-default a:visited {
  color: #333333
}

.left-nav #accordion .panel-default a:hover {
  color: #1e97d4;
  text-decoration: none
}

.left-nav #accordion .panel-default a:active {
  color: #1e97d4;
  text-decoration: none
}

.left-nav #accordion .panel-default a:focus {
  color: #1e97d4;
  text-decoration: none
}

.left-nav #accordion .panel-body {
  border-top: none !important;
  border-bottom: #1b96d4 1px solid
}

.waypoint-focus {
  color: #1e97d4 !important
}

.next-steps-body .p-margin {
  margin-left: 20px
}

.next-steps-body .img-margin {
  margin-bottom: 20px
}

.ns-dark-blue {
  color: #1f4d78
}

.ns-light-blue {
  color: #2e74b5
}

.next-steps-body img {
  width: 100%;
  height: auto
}

.getting-started-overview-body img {
  width: 100%;
  height: auto
}

.ref-doc-overview-content .product-header {
  font-family: Arial;
  font-size: 32px;
  line-height: 44px
}

.ref-doc-products ul {
  list-style-image: url("../images/pdf.png")
}

#forum-forum .forum-number-topics a {
  color: white
}

#forum-forum .forum-list-icon h3 {
  margin-top: 0px
}

a.af-button-large,
a.af-button-large:link,
a.af-button-large:visited {
  color: #ffffff;
  background-color: #009FD0;
  background-image: -moz-linear-gradient(center top, #00A4D7, #0197C6);
  background-repeat: repeat-x;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) inset, 0 0 2px 0 rgba(255, 255, 255, 0.1);
  color: #FFFFFF;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  font-weight: normal;
  line-height: 24px;
  margin-bottom: 0;
  padding: 4px 10px;
  text-align: center;
  vertical-align: middle
}

.view-advanced-forum-topic-list .table-responsive .topic-icon {
  padding-left: 35px;
  padding-bottom: 7px
}

.search-form .btn.btn-primary.form-submit {
  margin-top: 20px;
  margin-left: 10px
}

.region.region-sidebar-first.well h2 {
  font-size: 20px !important
}

.region.region-sidebar-first.well ul {
  padding-left: 10px !important
}
