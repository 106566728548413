.document-link {
  select.select-locale {
    margin-right: 10px;
  }

  a {
    &:focus {
      outline: 2px solid #5f60ff;
    }
  }
}
