.app-expiry-text {
  overflow: auto;
  
  .app-detail-value {
    display: flex;
  }

  .sidebar {
    float: left;
    height: auto;    
  }

  .icon-style {
    align-items: center;
    width: 18px;
    height: auto;
    display: block;
  }
  
  .page-wrap  {
    margin-left: 10px;
    align-self: flex-end;
  }
}