.toggle-component-wrapper {
  .toggle-parent-wrapper {
    display:-ms-grid;
    display:grid;
    -ms-grid-columns:25px 1fr;
    grid-template-columns:25px 1fr;

    .toggle-icon {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
      color: $color-text-1;
    }

    .toggle-parent-info-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }
  }

  .toggle-child-wrapper {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.35s ease-out;
    &.show-child-wrapper {
      max-height: 850px;
    }
  }
}