.sitemap-page-wrapper {
  padding: 10px 0 40px;
  .static-template-subHeader {
    border: none;
    padding-left: 20px;

    h3 {
      font-size: 20px;
    }
  }

  a {
    &:focus {
      outline: 2px solid #5f60ff;
    }
  }
}