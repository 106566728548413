.appsIntro-wrapper-with-outer-image {
  background-color: black;
  padding: 75px 0;
  color: $color-text-3;

  h3 {    
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }

  @media (max-width:768px) {
    h3 {
      font-size: 26px;
    }
  }

  .appsIntro-title-wrapper {
    font-family: ArtifaktLegend, ArtifaktElement, sans-serif;
  }

  .appsIntro-steps-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 45px 0 20px 0;
    text-align: center;
    margin: 0 -15px;

    .appsIntro-step-wrapper {
      width: 100%;
      padding: 0 15px;

      .appsIntro-step-image {
        padding: 0 0 10px 0;
        display: block;
        
        img {
          height: 200px;
          width: 200px;
        }
      }

      .appsIntro-step-title {
        font-size: 24px;
      }

      .appsIntro-step-summary {
        text-align: left;
        padding: 0 40px;

        span {
          display: block;
          float: left;

          &.dropcap {
            color: $color-text-6;
            font-size: 48px;
            line-height: 48px;
            padding-right: 15px;
            width: 15%;
          }

          &:nth-child(2) {
            padding: 1px 0 0 0;
            width: 85%;
          }
        }        
      }
    }

    @media (min-width: 992px) {
      .appsIntro-step-wrapper {
        width: 960px;
        flex: 1;
      }
    }
  }

  .appsIntro-button-wrapper {
    text-align: center;

    .btn {
      color: black;
      font-size: 18px;
      font-weight: lighter;
      padding: 17px 43px 16px 50px;
      text-decoration: none;

      &::before {
        content: '\e613';
        font-family: 'Autodesk-Icon-Fonts';
        font-size: 34px;
        display: inline-block;
        position: absolute;
        top: 6px;
        left: 9px;
      }

      &::after {
        font-size: 19px;
        top: 17px;
      }

      &:hover {
        color: black;
      }
    }
  }
}
