/* ALIGNMENT MIXINS */
@mixin center-align() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin vertical-align() {
  display: flex;
  align-items: center;
}

@mixin horizontal-align() {
  display: flex;
  justify-content: center;
}

/* LIST SPACINGS */

@mixin provide-list-padding() {
  > * {
    padding-right: 10px;
  }
  
  > *:last-child {
    padding-right: 0;
  }
}
