/*
 ============================================
 ICON FONTS
 ============================================
*/

[class^="icon-"], [class*=" icon-"] {
	font-family: 'Autodesk-Icon-Fonts';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-adsk-eye:before {
	content: "\e608";
}
.icon-adsk-settings:before {
	content: "\e609";
}
.icon-adsk-video:before {
	content: "\e60a";
}
.icon-adsk-message:before {
	content: "\e60b";
}
.icon-adsk-user:before {
	content: "\e60c";
}
.icon-adsk-arrows:before {
	content: "\e60d";
}
.icon-adsk-image:before {
	content: "\e60e";
}
.icon-adsk-audio:before {
	content: "\e60f";
}
.icon-adsk-lock:before {
	content: "\e610";
}
.icon-adsk-rss:before {
	content: "\e611";
}
.icon-adsk-chat:before {
	content: "\e612";
}
.icon-adsk-apps:before {
	content: "\e613";
}
.icon-adsk-arrow:before {
	content: "\e614";
}
.icon-adsk-arrow-next:before {
	content: "\e615";
}
.icon-adsk-arrow-prev:before {
	content: "\e616";
}
.icon-adsk-open:before {
	content: "\e617";
}
.icon-adsk-close:before {
	content: "\e618";
}
.icon-adsk-arrow-go:before {
	content: "\e619";
}
.icon-adsk-edit:before {
	content: "\e61a";
}
.icon-adsk-delete:before {
	content: "\e61b";
}
.icon-adsk-place-order:before {
	content: "\e600";
}
.icon-adsk-submit-order:before {
	content: "\e601";
}
.icon-adsk-order-details:before {
	content: "\e602";
}
.icon-adsk-return-subscription:before {
	content: "\e603";
}
.icon-adsk-cancel-subscription:before {
	content: "\e604";
}
.icon-adsk-product-price:before {
	content: "\e605";
}
.icon-adsk-get-invoice:before {
	content: "\e606";
}
.icon-adsk-get-licence:before {
	content: "\e607";
}