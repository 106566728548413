.articles-wrapper-with-outer-image {
  background-color: $color-background-1;
  padding: 75px 0;

  h3 {
    color: $color-text-5;
    font-size: 32px;
    text-align: center;
    margin-top: 0;
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 26px;
    }
  }

  .articles-content-header {
    font-family: ArtifaktLegend, ArtifaktElement, sans-serif;
  }

  .articles-types-container {
    display: flex;
    justify-content: center;
    padding: 45px 0 20px 0;
    text-align: center;
    margin: 0 -15px;

    .articles-types-wrapper {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .article-type-wrapper {
        width: 100%;
        margin: 0 15px;
        text-align: left;

        h3 {
          text-align: left;
          .article-type-title-link {
            font-size: 22px;
            text-decoration: none;
          }
        }

        .article-type-summary {
          padding: 10px 0 25px 0;
        }
      }

      @media (min-width: 768px) {
        .article-type-wrapper {
          width: 46%;
        }
      }

      @media (min-width:1200px) {
        .article-type-wrapper a {
          display: inline-block;
          float: none;
          text-align: left;
          margin-right: -4px;
        }
      }

      @media (max-width:1200px) {

        .article-type-wrapper:last-child a {
          margin-bottom: 0;
        }
      }
    }
  }
}
