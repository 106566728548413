.partner-overview-page-wrapper {
  .video-wrapper {
    margin: 30px 0 0;
    padding: 0 0 30px;

    >div {
      padding: 0px 30px;

      video {
        border: 1px solid $color-border-6;
      }
    }
  }
}