.products-wrapper-with-outer-image {
  background-color: $color-background-1;
  padding: 75px 0;

  h3 {
    color: black;
    font-size: 32px;
    text-align: center;
  }

  @media (max-width:768px) {
    h3 {
      font-size: 26px;
    }
  }

  .products-content-header {
    font-family: ArtifaktLegend, ArtifaktElement, sans-serif;
  }

  .products-types-container {
    display: flex;
    justify-content: center;
    padding: 45px 0 20px 0;
    text-align: center;
    margin: 0 -15px;

    .products-types-wrapper {
      width: 323px;        
      display: flex;
      flex-wrap: wrap;

      .product-type-wrapper {
        width: 293px;
        margin: 0 15px;

        a {
          margin-bottom: 20px;
          position: relative;
          width: 100%;

          color: $color-text-4;
          font-weight: bold;
          text-decoration: none;
          font-size: 17px;
          display: block;
          text-align: center;
          background-color: $color-background-1;

          border: 2px solid $color-border-2;
          overflow: hidden;
          -webkit-border-radius: 3px;
          -moz-border-radius: 3px;
          border-radius: 3px;
          -moz-box-shadow: 0px 2px 6px $color-box-shadow-2;
          -webkit-box-shadow: 0px 2px 6px $color-box-shadow-2;
          box-shadow: 0px 2px 6px $color-box-shadow-2;
          -webkit-transition: all .2s ease-in-out;
          -moz-transition: all .2s ease-in-out;
          -o-transition: all .2s ease-in-out;
          transition: all .2s ease-in-out;

          .product-type-image, .product-type-name {
            padding: 30px 18px 30px 18px;
            overflow: hidden;
            text-align: center;
          }

          .product-type-image {
            height: 165px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .product-type-name {
            height: 88px;
            color: black;
          }
        }

        a:hover {
          border: 2px solid $color-border-3;
        }
      }

      @media (min-width:1200px) {
        .product-type-wrapper a {
          display: inline-block;
          float: none;
          text-align: left;
          margin-right: -4px;
        }
      }

      @media (max-width:1200px) {

        .product-type-wrapper:last-child a {
          margin-bottom: 0;
        }
      }
    }

    @media (min-width: 646px) {
      .products-types-wrapper {
        width: 646px;
      }
    }
    
    @media (min-width: 992px) {
      .products-types-wrapper {
        width: 980px;
      }
    }
  }
}
