@import '@digital-hig/theme-data/lib/build/css/dhig-fonts.css';
@import './colors';
@import './fonts-loading';
@import './autodesk-icons';
@import './glyphicons-halflings-icons';
@import './custom-icomoon-icons';
@import './oldUIStyles';
@import './mixins';
@import '@hig/theme-data/build/scss/variables/_lightGrayMediumDensityTheme.scss';

html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: ArtifaktElement,sans-serif, Arial, 'Helvetica Neue', Helvetica,;
  font-size: 14px;
  line-height: 1.42857;
}

body.fontLoaded {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
}

@media (max-width: 701px) {
  body {
    width: 700px;
    overflow-x: scroll;
  }
}

/* CONTAINERS */
.container:after,
.container:before {
  content: ' ';
  display: none;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px; 
  }
}

@media (min-width: 1220px) {
  .container {
    width: 1200px;
  }
}

/* PERSONALIZED */

#app {
  background-color: $color-background-3;
  min-height: 100%;
  min-width: 100%;
}

/* Based on @digital-hig style */
#adsk-eprivacy-privacy-settings-footer-link,
.adsk-eprivacy-ccpa-link {
  border: none !important;
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
  text-decoration-color: black !important;

  &:hover {
    text-decoration: underline !important;
    text-decoration-thickness: 2px !important;
    text-decoration-color: #8080ff !important;
  }

  &:active {
    padding: 2px;
    border: 2px solid #8080ff !important;
    text-decoration: underline !important;
    text-decoration-thickness: 2px !important;
    text-decoration-color: #2bc275 !important;
  }

  &:visited {
    border-bottom: 1px solid black;
    opacity: 0.6 !important;
  }
}

a {
  color: black;
  outline-offset: 2px;
  outline-color: transparent;

  &:hover {
    text-decoration-color: #8080ff;
    text-decoration-thickness: 2px;
  }

  &:active {
    text-decoration-color: #2bc275;
    outline: 2px solid transparent;
  }

  &:visited {
    text-decoration-thickness: 1px;
    opacity: 0.6;
  }
}

.blue-line-links {
  cursor: pointer;
  color: black;
  text-decoration: underline;

  &:focus,
  &:hover {
    color: black;
    text-decoration: underline;
  }

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

a.underline-links {
  cursor: pointer;
  color: black;
  text-decoration: none;
  border-bottom: 1px solid black;

  &:focus,
  &:hover {
    color: black;
    text-decoration: none;
    border-bottom: 1px solid $color-accent-iris;
  }

  &:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

.center-align {
  @include center-align();
}

.vertical-align {
  @include vertical-align();
}

.horizontal-align {
  @include horizontal-align();
}

.hide {
  display: none !important;
}
.highlight {
  background-color: $color-background-2;
  color: $color-text-1;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid $color-border-1;
  display: block;
  padding: 20px;
  margin: 0 0 10px;
  font-size: 12px;
  line-height: 2;
  border-radius: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  border-radius: 3px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: monospace;
}

/* PDF ICON */
.ref-doc-pdf-links {
  list-style-image: url('../images/pdf.png');
}
