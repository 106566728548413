$blog-square-dimension: 100px;

.blog-page-wrapper {
  .blog-content-wrapper {
    padding: 10px 60px 40px 0;
    .article-listing-wrapper {
      clear: both;
      border-bottom: 1px solid $color-border-1;
      overflow: auto;
      padding: 15px 0 10px;
      .empty-wrapper {
        width: $blog-square-dimension;
        height: $blog-square-dimension;
        float: left;margin: 15px 20px 0 0;
        border: none;
        border-radius: 0;
      }
      .date-wrapper {
        width: $blog-square-dimension;
        height: $blog-square-dimension;
        background: black;
        text-align: center;
        margin: 15px 20px 0 0;
        border: none;
        border-radius: 0;
        color: $color-text-3;

        .date-month {
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 1.3em;
          text-transform: uppercase;
        }

        .date-day {
          font-size: 2.5em;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          color: white;
          margin-top: -7px;
        }
      }
      .article-info-wrapper {
        margin: -7px 10px 7px;
        .article-title {
          a {
            color: $color-text-1;

            &:focus {
              outline: 2px solid #5f60ff;
            }
          }
        }
        .article-author {
          color: $color-text-2;
          margin: 15px 0 0;
        }
        .article-date {
          color: rgba(0,0,0,0.54);
          margin-top: 3px;
          font-size: 13px;
        }
        .article-summary {
          font-size: 1.2em;
          margin: 20px 0 0;
        }
        .article-button-link {
          position: relative;
          #read-article-blog-button {
            padding-right: 45px;
            &:after {
              content: '\e619';
              font-family: 'Autodesk-Icon-Fonts';
              font-size: 17px;
              font-weight: lighter;
              display: inline-block;
              padding-left: 10px;
              top: 10px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
