// Catchall baseclass
.icomoon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Icomoon-icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Individual icons
.icomoon-icon-information               { &:before { content: "\e900"; } }
