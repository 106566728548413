.navigation-container {
  padding: 0;
  box-shadow: 0px -1px 0px hsla(0,0%,100%,0.2);

  .nav-item {
    list-style-type: none;
  }

  .navigation-type-wrapper {
    display: flex;
    
    .container {

      height: 100%;

      .navbar-nav {
        flex-direction: row;
        padding: 0;

        .nav-item {
          height: 100%;
          list-style-type: none;
          float: left;

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .main-navigation-container {
    background: rgb(10,10,10);

    .container  .navbar-nav {
      margin: 0;
      .nav-item  a {
        padding: 15px 15px;
        color: $color-text-3;
        height: 100%;
        @include vertical-align();

        &:hover {
          color: $color-text-3;
          text-decoration: none;
          border-bottom: 2px solid rgba(255, 255, 255, 0.6);
          padding: 15px 15px 13px 15px;
        }

        &:focus {
          outline: 2px solid transparent;
        }

        &.navbar-mainNav-selected {
          border-bottom: 2px solid white;
          padding: 15px 15px 13px 15px;
        }
      }
    }
  }

  .sub-navigation-container {
    border-bottom: 1px solid $color-border-bottom-1;

    .container .navbar-nav {
      margin: 0;
      .nav-item a {
        padding: 0 15px;
        color: $color-text-9;
        padding: 18px 0;
        margin: 0 32px 0 0;
        line-height: 15px;
        height: 100%;
        @include vertical-align();

        &:hover {
          color: $color-text-8;
          text-decoration: none;
        }

        &:focus {
          outline: 2px solid transparent;
        }

        &.navbar-subNav-selected {
          color: black;
          padding: 18px 0 16px;
          border-bottom: black solid 2px;
        }
      }
    }
  }
}
