.blog-article-page-wrapper {
  .article-content-wrapper {
    padding: 30px 0 60px;

    p {
      font-size: 1.2em;
      &.paragraph-top-spacing {
        margin-top: 35px;
      }
    }

    table {
      border: 1px solid $color-border-10;
      border-collapse: collapse;
      text-align: center;
  
      th, td {
        border: 1px solid $color-border-10;
        padding: 5px 10px;
      }
    }

    li {
      margin: 10px 0;
    }
  }
}