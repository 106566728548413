.api-doc-page-wrapper {
  .company-info-wrapper {
    margin: 20px 0 0;
    padding-bottom: 30px;
  }

  .products-wrapper {
    .product-wrapper {
      padding: 16px 0;
      border-bottom: 1px solid $color-border-bottom-1;

      &:last-child {
        border: none;
      }

      h4 {
        margin: 0 0 10px;;
        text-transform: capitalize;
      }

      a {
        &:focus {
          outline: 2px solid #5f60ff;
        }
      }
    }
  }
}