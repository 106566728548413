.input-description-wrapper {
  width: 100%;

  .input-text-wrapper {
    display: flex;
    position: relative;
    align-items: baseline;
  }
}

.modify-app-input-wrapper {
  padding-bottom: 20px;

  .modify-app-internal-name-wrapper {
    font-size: 85%;
    margin: 3px 0px 0;
  }

  label {
    display: block;
  }

  input,
  textarea {
    display: inline-block;
    height: 24px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: $color-text-13;
    background-color: $color-background-1;
    background-image: none;
    border: 1px solid $color-border-1;
    border-radius: 4px;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    border-color: rgba(0, 0, 0, 0.23);

    &:hover {
      border: 1px solid $color-text-1;
    }

    &:focus {
      border: 1px solid $color-border-6;
      outline: 0;
      -webkit-box-shadow: inset 0 1px 1px $color-box-shadow-3, 0 0 8px $color-box-shadow-4;
      box-shadow: inset 0 1px 1px $color-box-shadow-3, 0 0 8px $color-box-shadow-4;
    }

    &.input-error {
      border-color: $color-border-7 !important;
    }
  }

  textarea {
    height: 110px;
  }

  .input-format-notify-wrapper {
    display: inline-block;
    position: relative;
    width: 10px;
    height: 30px;
  }

  .notify-input-error-message {
    padding: 3px 0 0;
    font-size: 85%;
    color: $color-text-10;
    text-align: right;
  }

  input,
  .notify-input-error-message {
    width: calc(100% - 40px);
  }

  .app-label-with-icon {
    display: grid;
    grid-template-columns: 80% 30%;

    display: -ms-grid;
    -ms-grid-columns: 80% 30%;

    .icon-style {
      width: 10%;
      height: auto;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
  }

  .notification-msg-text {
    text-align: left;
    font-size: 12px;
    padding-bottom: 7px;
  }

  .display-content-width {
    width: 620px;
  }

  .app-expire-key-wrapper {
    padding-top: 10px;
    margin-right: 10px;
    border: 1px solid $color-background-4;

    display: -ms-grid;
    -ms-grid-columns: 50% 35% 15%;

    width: 620px;

    > div {
      padding: 0 15px 15px;
    }
  }

  .app-detail-info-wrapper {
    padding-top: 10px;
    margin-right: 10px;
    border: 1px solid $color-background-4;

    .app-detail-three-level-info {
      display: grid;
      grid-template-columns: 50% 35% 15%;

      display: -ms-grid;
      -ms-grid-columns: 50% 35% 15%;
    }

    .app-detail-single-level-info {
      display: grid;
      grid-template-columns: 100%;

      display: -ms-grid;
      -ms-grid-columns: 100%;
    }

    .app-detail-info-left-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    .app-detail-info-center-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 1;
    }
    .app-detail-info-right-wrapper {
      -ms-grid-row: 1;
      -ms-grid-column: 2;
    }

    > div {
      padding: 0 15px 15px;
    }
  }

  .icon-pointer {
    cursor: pointer;
  }

  .delete-icon {
    text-align: right;
  }

  .app-credential-add,
  .app-credential-remove {
    width: 170px;
  }

  @media (min-width: 660px) {
    input,
    .notify-input-error-message {
      width: 620px;
    }
  }
}
