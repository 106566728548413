.userInputType-wrapper.userInputType-textarea-with-char-count-wrapper {

  textarea {
    resize: none;
    overflow: hidden;
    height: 80px;
    border: 1px solid $color-border-1;
    border-color: rgba(0, 0, 0, 0.23);
  }

  .textarea-is-necessary {
    color: $color-text-10;
  }

  .hover-helptips-wrapper {
    top: 0px;
  }
  
  .textarea-value-count {
    margin-top: 4px;
    text-align: right;
    font-size: 12px;
    &.alert-user {
      color: $color-text-10;
    }
  }
}