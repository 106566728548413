.static-template-header, .static-template-subHeader {
  padding: 30px 0 9px;
  color: $color-text-5;

  h1, h3 {
    margin: 10px 0;
    text-transform: capitalize;
    font-size: 32px;
  }
  
  h3 {
    font-size: 24px;
    font-family: ArtifaktLegend, ArtifaktElement, sans-serif;
  }
}

.content-wrapper-padding {
  padding-top: 50px;

  &.static-template-content-wrapper {
    display:-ms-grid;
    display:grid;
    -ms-grid-columns:1fr 2fr;
    grid-template-columns:1fr 2fr;    
  
    .static-template-anchor-container {
      grid-row: 1;
      grid-column: 1;
      -ms-grid-row:1; 
      -ms-grid-column:1; 
      -ms-grid-column-span:1;
      .static-template-anchor-wrapper {
        margin: 0 15px 0 0;
        color: $color-text-1;
        &.fix-anchor-menu {
          position: fixed;
          top: 50px;
          width: 27%;
          max-width: 297px;
          min-width: 210px;
        }
    
        .static-template-anchor-section {
          border-bottom: 1px solid $color-border-1;
    
          .anchor-menu-parent {
            padding: 10px 15px;
            font-size: 16px;
            line-height: 24px;
          }
    
          .anchor-menu-children {
            padding: 15px;
            font-size: 15px;
            line-height: 23px;
    
            .anchor-menu-child {
              padding-left: 30px;
            }
          }
        }
    
        a, .anchor-menu-parent, .anchor-menu-child, .anchor-menu-child-name {
          color: unset;
          text-decoration: none;
    
          &:hover, &.anchor-menu-selected {
            color: $color-link-select-1;
            font-weight: 700;
          }
        }
    
        .anchor-menu-child-number {
          color: $color-text-1;
          text-decoration: none;
    
          &:hover, &.anchor-menu-selected {
            color: $color-text-1;
          }
        }
      }
    }
  
    .static-template-body-wrapper {
      margin: 0 15px;
      grid-row: 1;
      grid-column: 2;
      -ms-grid-row:1; 
      -ms-grid-column:2; 
      -ms-grid-column-span:2;
  
      .right-indent-wrapper {
        margin-left: 40px;
      }
  
      .waypoint-divider {
        padding: 5px;
      }
    }
  }
}
