.hover-helptips-wrapper {
  position: absolute;
  top: 13px;
  left: 13px;
  text-align: center;
  background-color: $color-background-5;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  line-height: 26px;
  cursor: default;

  &:before {
    content:'?';
    font-weight: bold;
    color:$color-text-3;
  }

  &:hover .hover-popup-wrapper {
    display:block;
    transform-origin: 100% 0%;
  
    -webkit-animation: hoverHelpTipFadeIn 0.3s ease-in-out;
    animation: hoverHelpTipFadeIn 0.3s ease-in-out;  
  }

  .hover-popup-wrapper {    /* The tooltip */
    display: none;
    text-align: left;
    background-color: $color-background-9;
    padding: 20px;
    width: 300px;
    position: absolute;
    border-radius: 5px;
    box-shadow: -1px 1px 4px 0px $color-box-shadow-2;
    right: -5px;
    top: 30px;
    color: black;
    font-size: 13px;
    line-height: 1.4;
    border: 1px solid $color-border-6;
    z-index: 100;

    &:before { /* The pointer of the tooltip */
      position: absolute;
      content: '';
      width: 0;
      height: 0;
      border: 6px solid $color-border-4;
      border-bottom-color: $color-border-bottom-3;
      right: 10px;
      top: -13px;
    }

    &:after { /* Prevents the tooltip from being hidden */
      width:100%;
      height:40px;
      content:'';
      position: absolute;
      top:-40px;
      left:0;
    }
  }
}
/* CSS animation */

@-webkit-keyframes hoverHelpTipFadeIn {
  0% { 
      opacity:0; 
      transform: scale(0.6);
  }

  100% {
      opacity:100%;
      transform: scale(1);
  }
}

@keyframes hoverHelpTipFadeIn {
  0% { opacity:0; }
  100% { opacity:100%; }
}