$links-height: 25px;

@mixin link-separator {
  border-right: $color-seperator-1 solid 1px;
  &:last-of-type {
    border-right: none;
  }
}

footer {
  @include horizontal-align();
  flex-wrap: wrap;
  color: black;
  background-color: $color-background-4;
  text-align: center;
  padding: 20px 100px;

  section {
    width: 100%;
    margin-bottom: 20px;
    @include horizontal-align();

    &:last-of-type {
      margin: 0;
    }

    .languages-wrapper {
      @include provide-list-padding();
      span.language-wrapper {
        @include link-separator();
        min-width: 50px;
        padding: 0 10px;
        height: $links-height;
      }
    }

    .footer-sections {
      margin-bottom: 20px;

      &.copy-right-link {
        margin: 0;

        a {
          &:focus {
            outline: 2px solid #5f60ff;
          }
        }
      }

      &.footer-links-section {
        @include provide-list-padding();
        display: flex;
        >a {
          @include vertical-align();
          padding: 0 10px;
          @include link-separator();
          height: $links-height;

          &:focus {
            outline: 2px solid #5f60ff;
          }
        }
      }
    }
  }
}