.products-types-container {
  display: flex;
  justify-content: center;
  padding: 20px 0 0;
  text-align: center;
  margin: 0 -10px;

  h3 {
    color: $color-text-1;
    font-size: 32px;
    text-align: center;
  }

  @media (max-width:768px) {
    h3 {
      font-size: 26px;
    }
  }

  .products-types-wrapper {
    width: 240px;        
    display: flex;
    flex-wrap: wrap;

    .product-type-wrapper {
      width: 210px;
      margin: 0 10px;

      .product-type-option {
        margin-bottom: 20px;
        position: relative;
        width: 100%;

        color: $color-link-2;
        text-decoration: none;
        font-size: 17px;
        display: block;
        text-align: center;
        background-color: $color-background-1;

        border: 2px solid $color-border-2;
        overflow: hidden;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        -moz-box-shadow: 0px 2px 6px $color-box-shadow-2;
        -webkit-box-shadow: 0px 2px 6px $color-box-shadow-2;
        box-shadow: 0px 2px 6px $color-box-shadow-2;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;

        .product-type-image, .product-type-name {
          padding: 30px 18px 0 18px;
          overflow: hidden;
          text-align: center;
        }

        .product-type-image {
          height: 150px;

          img {
            max-width: 105px;
            max-height: 105px;
          }
        }

        .product-type-name {
          color: black;
          height: 78px;
          padding: 5px 18px 40px;
        }
      }

      .product-type-option:hover {
        border: 2px solid $color-border-3;
      }

      .product-type-option-selected {
        border: 2px solid $color-border-9 !important;
      }

      .product-type-option-disabled {
        cursor: default;
      }
    }

    .product-type-wrapper-disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    @media (min-width:1200px) {
      .product-type-wrapper a {
        display: inline-block;
        float: none;
        text-align: left;
        margin-right: -4px;
      }
    }

    @media (max-width:1200px) {

      .product-type-wrapper:last-child a {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 500px) {
    .products-types-wrapper {
      width: 480px;
    }
  }
  
  @media (min-width: 750px) {
    .products-types-wrapper {
      width: 720px;
    }
  }
  
  @media (min-width: 992px) {
    .products-types-wrapper {
      width: 960px;
    }
  }

  @media (min-width: 1220px) {
    .products-types-wrapper {
      width: 100%;
    }
  }
}
